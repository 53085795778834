@import '../../../../styles/constants.scss';

.workOrder_validity_selector__overlay_head {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.workOrder_validity_selector__title {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  font-family: 'Plus Jakarta Sans';
  color: #000000;
}

.workOrder_validity_selector_date_picker_container {
  display: flex;
  margin-top: 2rem;
  width: 90%;
  align-self: center;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.workOrder_validity_selector-save-button {
  padding: 4px 18px;
  margin-top: 12px;
  background-color: $primary;
  border: none;
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.workOrder_validity_selector-disbale-btn {
  background-color: $muted;
}

.workOrder_validity_selector-data-time-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.workOrder_validity_selector-circular-progress {
  padding: 0px 12px;
  margin: 0px;
  border-radius: 4px;
  background-color: $muted;
  p {
    margin-left: 8px;
    color: white;
  }
}
.workOrder_validity_selector-error-tag {
  color: $error;
  font-size: 14px;
}
