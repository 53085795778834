@import "../../../styles/color-codes";

.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
}

.labelContainer > input {
  flex: 1;
}
.labelContainer ~ select {
  flex: 1;
  padding: 5px;
  border: 2px solid $BORDERS;
  background-color: $WHITE;
  border-radius: 5px;
}

.labelContainer ~ select:focus {
  border-color: $PRIMARY;
}
.labelContainer ~ select:active {
  border-color: $PRIMARY;
}

.labelContainer > label {
  font-size: 13px;
  margin: 0 !important;
}

.labelContainer {
  min-width: 85px;
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.icon {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}
