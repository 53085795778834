@import '../../../styles/constants.scss';

.checklist_builder-main-container {
  height: 100vh;
  background-color: white;
  font-family: 'Outfit';
}

.checklist-builder__container-v1 {
  display: flex;
  flex-direction: column;
  width: calc(100% - 180px);
  margin-left: 180px;
  padding: 12px 12px 12px 12px;
  overflow-y: scroll;
  max-height: calc(100vh - 40px);
}

.checklist-builder__cards-row{
  display: flex;
  flex-wrap: wrap;
}
.checklist-builder__flex-row_v1 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  // margin: 1rem 0;
  // margin-bottom: 1rem;
}
.checklist-builder__no-checklist-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  margin: 4rem 0;
}

.checklist-builder__no-checklist-h{
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: $primary-dark;
}

.checklist-builder__no-checklist-p{
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $primary-dark;
}

.MuiPaper-elevation1 {
  box-shadow: none;
}

.checklist-builder__no-checklist-margin-hor-16{
  margin: 16px 0;
}
.checklist-builder_scroll-view{
  overflow-y: scroll;
  // height: 65vh;
}
.shareContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#shareStyle:hover {
  border-radius: 20px;
  background: var(--fontcolorsgray-3, #F0F0F0);
}
#shareStyle:active {
  border-radius: 20px;
  background: var(--fontcolorsgray-4, #DEDEDE);
}
.editWrap{
  display: flex;
  flex-direction: row;
}
.checklist_dashboard-menu-item {
  &:hover {
    background-color: $gray_1;
  }
}
.checklist_dashboard-option-menu {
  div {
    margin-bottom: 4px;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 6px;
    margin-right: 24px;
    cursor: pointer;
    img {
      margin-right: 8px;
    }
  }
}
.duplicateTitle{
  color: var(--TEXT, #2B323B);
  font-family: 'Jost';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.deleteTitle{
  color: var(--ERROR, #E92B36);
  font-family: 'Jost';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.checklist-builder__delete-loader-wrap{
  height: 156px;
}
.checklist-builder__delete-loader{
  margin-top: 67px;
}
.checklist-builder__deleteMoal-title {
  font-family: 'Outfit', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 4px;
  color: #2B323B;
}
.checklist-builder__deleteMoal-description {
  font-family: 'Jost', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
  color: #2B323B;
}
.checklist-builder__deleteMoal-button_container{
  display: flex;
  justify-content: flex-end;
}
.checklist-builder__deleteMoal-cancel_button{
  color: #404040;
  border-radius: 4px;
  border: 1px solid #D6D6D6;
  border-color: #D6D6D6;
  background-color: #FFFFFF;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 8px;
}
.checklist-builder__deleteMoal-delete_button{
  color: #FFFFFF;
  border-radius: 4px;
  background-color: #E92B36;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}
.checklist-builder__deleteMoal-wo-description{
  font-family: 'Jost';
  font-size: 16px;
  font-weight: 400;
  color: #808080;
}
.checklist-builder__deleteMoal-pdf-description{
  font-family: 'Jost';
  font-size: 16px;
  font-weight: 400;
  color: #808080;
  margin-bottom: 24px;
}
.checklist-builder__deleteMoal-wo-description-wrap{
  margin-bottom: 24px;
}
.custom-bullet {
  list-style: none;
  padding-left: 20px;
}

.custom-bullet li {
  position: relative;
  padding-left: 5px;
  margin-bottom: 5px;
}

.custom-bullet li:before {
  content: "\2022";
  position: absolute;
  left: -10px;
  top: 9px;
  color: #808080;
  font-size: 7px;
  line-height: 1;
}

.checklist_builder-banner-right-row {
  gap: 1rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.checklist-builder-csv-download-button {
  background-color: $untouched_input_bg;
  color: $primary;
  font-weight: 500;
  border: 0.1rem solid $primary;
}

.checklist-builder-create-new-button {
  border: 0.1rem solid $primary;
}
