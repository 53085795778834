@import "../../../styles/mixins";

.overview-wrapper {
  max-width: calc(100% - 30px);
  margin: 0 auto 0 0;
}

.routines-home {
  margin-bottom: 20px;
  &__title {
    @include route-title();
    margin-bottom: 24px;
  }

  &__subtitle {
    @include route-subtitle();
    display: flex;
    align-items: center;
    color: #e92b36;

    svg {
      margin-right: 4px;
      height: 18px;
      transform: rotate(90deg);
      fill: #e92b36;
    }
  }

  &__subtitle--positive {
    color: #7eaa56;

    svg {
      transform: rotate(-90deg);
      fill: #7eaa56;
    }
  }

  &__card {
    min-height: 125px;
    padding: 25px 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__title {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #2b323b;
      text-transform: uppercase;
      padding-left: 15px;
    }
  }

  &__filters-row {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 16px;

    .multi-select {
      margin-right: 28px;
    }
  }

  h3 h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #2b323b;
  }

  .ReactTable.-striped .rt-tr.-even {
    background-color: #f7f7f7;
  }

  .routine-card-group {
    margin: 0 -30px;
    .vertical-line-separator-routine{
      width: 2px;
      height: 30px;
      margin-right: 15px;
      margin-top: 8px;
      margin-left: 1px;
      background-color: lightgray;
      border-radius: 4px;
    }
    .total-progress-of-position{
      text-align: right;
      font-size: 16px;
      margin-top: 8px;
      margin-right: 5px;
      font-weight: 600;
      color: #878787;
    }
  }
}

.iframe-container{
  width: 100%;
  height: 85vh;
}

.iframe{
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 4px;
}

.company_analytics-container {
  width: calc(100% - 210px);
  margin-top: 1rem;
  overflow-y: scroll;
}

.company_analytics-sidebar-and-iframe-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.company_analytic-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'Outfit';
  background-color: white;
}