@import '../../../styles/constants.scss';
.page-container{
  height: 100vh;
  background-color: white;
  font-family: 'Outfit';
}
.um__container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 180px);
  margin-left: 180px;
  padding: 8px 12px 8px 12px;
  position: relative;
  overflow-y: scroll;
  max-height: calc(100vh - 60px);
}
.um__header{
  font-family: 'Outfit';
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  color: #2B323B;
}
.um__cards-row{
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
}
.um__flex-row{
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 24px;
}

.um__scroll-view{
  overflow-y: scroll;
  height: 65vh;
}
.um__scroll-view::-webkit-scrollbar{
  display: none;
}
#shareStyle:hover {
  border-radius: 20px;
  background: var(--fontcolorsgray-3, #F0F0F0);
}
#shareStyle:active {
  border-radius: 20px;
  background: var(--fontcolorsgray-4, #DEDEDE);
}
.um__title{
  font-weight: 500;
  font-size: 17px;
  font-family: Jost;
  line-height: 24px;
  color: #404040
}
.um__search{
  input {
    border-radius: 8px;
  }
}
#root > div.page-container > div.um__container > div.um__cards-row.um__scroll-view > div > table > thead {
  display: none;
}
.um__shareContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.iframe_tutorial {
  height: 100vh;
  border: none;
}