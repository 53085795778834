@import "../../../../styles/constants.scss";

.checklistHeader__container{
  width: 100%;
  padding: 10px 0px 0px 0px;
  background-color: $primary-30;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
}
.checklistHeader__button-container{
  display: flex;
  align-items: center;
  gap: 12px;
  padding-right: 50px;
  width: 35%;
  padding-bottom: 12px;
  justify-content: end;
}
.checklist_header-tabs-container {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.checklist_header-back-and-title-container {
  width: 35%;
  display: flex;
  padding-left: 110px;
  flex-direction: row;
  justify-content: center;
  // align-items: center;
  background-color: transparent;

@media only screen and (min-width: 768px) {
  padding-left: 1rem;
} 

@media only screen and (min-width: 1024px) {
  padding-left: 2rem;
}
}
.remove-extra-padding-margins {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.checklistHeader__back-v1{
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: $primary;
  gap: 8px;
  min-width: 85px;
  margin-bottom: 18px;
}
.checklistHeader_input-v1{
  padding: 8px;
  font-size: 18px;
  background-color: $gray_0;
  border: 1px solid $gray_4;
  border-radius: 4px;
  outline: none;
  margin-bottom: 5px;
  cursor: pointer;
  &:focus{
    outline: none;
    box-shadow: 0px 0px 0px 4px #E2E0FE;
  }
}

.checklistHeader_edit{
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #2B323B;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid transparent;
  overflow-wrap: anywhere;
}
.checklistHeader__edit-container-v1{
  min-width: 16rem;
  display: flex;
  flex-direction: column;
}
.checklistHeader__tab-edit-v1{
  width: 85%;
  margin-left: 3%;
}
.checklist-header-action-button-share {
  height: 36px;
  width: 130px;
  background-color: white;
  color: #000;
  font-size: 16px;
  flex-direction: row;
  justify-content: space-around;
  font-weight: 500;
  align-items: center;
}
.checklist-header-action-button-save {
  width: 130px;
  height: 36px;
  background-color: #6C63FF;
  color: #FFF;
  font-size: 16px;
  flex-direction: row;
  justify-content: space-around;
  font-weight: 500;
  align-items: center;
}
.checklist-header__version-text {
  color: #808080;
  font-size: 12px;
  padding-bottom: 4px;
}