.user-checklistHeader__back {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
  gap: 8px;
  padding: 32px 32px 0px 32px;
  width: 100% !important ;
}
.download-checklist-container{
  display: flex !important;
  gap: 4px !important;
  color: #6C63FF !important;
  cursor: pointer !important;
}
.download-checklist{
  font-weight: 600 !important;
  font-size: 14px !important;
}