@import '../../../styles/constants.scss';

$GRAY_9: #808080;
$GRAY_6: #b5b5b5;
$GRAY_4: #dedede;
$GRAY_10: #404040e2;
$GRAY_8: #878787;
$GRAY_2: #f2f2f2;
$BLUE_1: #2574fb;
$DANGER: #c00000;
$TEXT_COLOR: #2b323b;
.popupContainer{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-self: stretch;
  height: 80vh;
}
.quesion_popup-input-style {
  border-radius: 6px;
  border: 1px solid #DEDEDE;
  background: #FAFAFA;
  color:#404040;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 100%;
  padding: 18px 14px 18px 14px;
  &:focus {
    border: 1px solid #6C63FF;
    box-shadow: 0px 0px 0px 4px #E2E0FE;
  }
}

.questionContainer{
  display: flex;
  width: 30%;
  height: 79vh;
  flex-direction: column;
  align-self: stretch;
  border-top: none;
  border-right: 1px solid $gray_4;
}
.question_popup-search-input{
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 16px;
  margin-bottom: 32px;
}
.questionInputLayout{
  display: flex;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $gray_4;
  padding: 9px 4px 9px 40px;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}
.questionInputLayout:last-child{
  margin-bottom: 0px;
}
.comment-modal{

}
.question_popup-border-bottom {
  border-bottom: 1px solid rgba(182, 182, 182, 0.25);
}
.optionBox{
  display: flex;
  align-items: center;
  padding: 12px;
  flex: 1 0 0;
  text-align: start;
  align-items: flex-start;
  &:hover {
    background-color: #F1F0FF70;
  }
}
.question_popup-question-selected {
  background-color: #F1F0FF;
}
.list-container{
  overflow-y: auto;
  height: 100%;

}
.titleStyle{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.question_popup-modal {
  &__content {
    width: 80%;
    top: 12%;
    left: 50%;
    position: fixed;
    transform: translate(-50%);
    background-color: white;
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    flex: 1 1 auto;
    &:focus {
      outline: none;
    }
  }
}

.questions_popup-configure-bits-wrapper {
  display: flex;
  flex: 1;
  width: 50%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
  padding: 18px 24px 80px 24px;
  p {
    width: 100%;
    // margin-top: 32px;
    // padding: 118px 124px 80px 24px;
    // margin-bottom: 32px;
    align-self: flex-start;
    font-weight: 700;
    padding: 2px 2px 4px 12px;
  }
}
.questions_popup-configure-bits-wrapper-inner{
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow-y: scroll;
}
.pop-up-input-container{
  display: flex;
  width: 100%;

  padding: 0px 8px 2px 8px;
  // padding-bottom: 18px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

}
.QuestionPopUp__w-full {
  width: 100%;
}
.QuestionPopUp__flex-end {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  bottom: 12px;
  right: 12px;
}
.QuestionPopUp__flex-1{
  display: flex;
  flex: 1;
  flex-direction: column;
}
.defaultAnswerDescription{
  color: #808080;
  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  align-items: 'flex-start';
  width: 100%;
  padding: 0px 4px;
}
.uploadImageErrorTitle{
  color: red;
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 500 !important;
  text-align: end;
}