$GRAY_9: #808080;
$GRAY_6: #b5b5b5;
$GRAY_4: #dedede;
$GRAY_10: #404040e2;
$GRAY_8: #878787;
$GRAY_2: #f2f2f2;
$BLUE_1: #2574fb;
$DANGER: #c00000;
$TEXT_COLOR: #2b323b;
.comment-box {
  width: 100%;
  & > :nth-child(1) {
    border: none !important;
  }
  & > :nth-child(2) {
    border: none !important;
  }
  .comment-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 24px 0px;
    border-bottom: 1px solid $GRAY_4;
    width: 100%;
    .user_profile {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-right: 8px;
      &__image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 5px;
        object-fit: cover;
      }
    }
    .comment-input-container {
      width: 100%;
      input {
        align-self: center;
      }
    }
    .comment_content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 70%;
      &__header {
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: none;
        border: none;
        .name {
          font-family: Roboto;
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 140%;
        }
        .created-at {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 140%;
          color: $GRAY_8;
          text-transform: none;
          &::first-letter {
            text-transform: capitalize;
          }
        }
      }
      .body {
        margin-bottom: 12px;
        .message {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 140%;
          color: $GRAY_9;
          white-space: pre-wrap;
          border: none;
          word-wrap: break-word;
        }
      }
      .comment-footer {
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          color: $BLUE_1;
          margin-right: 6px;
        }
      }
    }
    .actionlog-comment-input {
      width: 100%;
      > p {
        width: 100%;
        .invalid-comment-description {
          color: $DANGER;
          font-size: 14px;
        }
      }
    }
    .action-area {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 12px;
      .save-button {
        height: 32px;
        margin-right: 16px;
        font-size: 12px;
        padding: 2px 5px;
        background-color: $BLUE_1;
        color: white;
        text-transform: none;
        font-weight: 500;
        &:focus {
          outline: none;
        }
      }
      .cancel-button {
        text-transform: none;
        height: 32px;
        font-size: 12px;
        color: $TEXT_COLOR;
        padding: 2px 5px;
        font-weight: 500;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.actionlog-comment-textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  padding: 8px 16px;
  box-sizing: border-box;
  border: 1px solid $GRAY_4;
  border-radius: 4px;
  color: $GRAY_8;
  word-wrap: break-word;
  &:focus-visible {
    border: 1px solid $GRAY_9;
    border-radius: 4px;
    outline: none;
    color: $TEXT_COLOR;
  }
  &:focus {
    border: 1px solid $GRAY_9;
    border-radius: 4px;
    outline: none;
    color: $TEXT_COLOR;
  }
  &.en[contentEditable]:empty::before {
    content: "Add comment...";
    color: $GRAY_8;
  }
  &.es[contentEditable]:empty::before {
    content: "Agregar comentario...";
    color: $GRAY_8;
  }
  &.invalid-comment {
    border: 1px solid $DANGER;
  }
}

.comment-delete-modal {
  &__content {
    width: 400px;
    top: 150px;
    left: 50%;
    position: fixed;
    transform: translate(-50%);
    background-color: white;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    &:focus {
      outline: none;
    }
    header {
      -webkit-box-align: center;
      align-items: center;
      display: flex;
      flex: 0 0 auto;
      -webkit-box-pack: justify;
      justify-content: space-between;
      transition: box-shadow 200ms ease 0s;
      z-index: 1;
      padding: 24px 24px 22px;
      box-shadow: none;
      span {
        font-size: 20px;
        font-style: inherit;
        font-weight: 500;
        letter-spacing: -0.008em;
        line-height: 1;
      }
    }
    &__body {
      flex: 1 1 auto;
      overflow: hidden auto;
      padding: 2px 24px;
    }
    footer {
      -webkit-box-align: center;
      align-items: center;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      transition: box-shadow 200ms ease 0s;
      z-index: 1;
      padding: 22px 24px 24px;
      box-shadow: none;
      -webkit-box-pack: end;
      justify-content: flex-end;
      .delete_button {
        height: 32px;
        margin-right: 16px;
        font-size: 12px;
        padding: 2px 5px;
        background: $DANGER;
        color: white;
        text-transform: none;
        font-weight: 500;
        &:focus {
          outline: none;
        }
      }
      .cancel-button {
        text-transform: none;
        height: 32px;
        font-size: 12px;
        color: $TEXT_COLOR;
        padding: 2px 5px;
        font-weight: 500;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.empty-comments {
  margin-top: 80px;
  display: block;
  text-align: center;
}
