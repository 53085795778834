.management-tool-container {
  .selection_dropdown {
    min-width: 240px;
  }
  .MuiSelect-outlined.MuiSelect-outlined {
    padding: 10px;
  }
  .extra_title {
    text-transform: uppercase;
  }
}
