.filter-on-click-dropdown {
  .filter-dropdown-container {
    position: relative;
    display: inline-block;
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #fff;
      width: 280px;
      box-shadow: 0 8px 16px 0 rgba(201, 201, 201, 0.5);
      z-index: 15;
      margin-top: 8px;
      a,
      .option {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
      }
      .option {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
    .filter-header{
      min-width: 140px;
      max-width: 140px;
      text-align: center;
      margin-top: 5px;
      overflow: hidden;
      .due-date-selected{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        img {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }
      }
    }
  }
  .hide {
    display: none;
  }
  
  .navbar-element-container {
    position: absolute;
    top: 1.05rem;
    right: 3.5rem;
  }
}
