.basic-button {
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 1em;
  outline: none;
  padding: 8px 18px;
  background-color: #2574FB;
  text-align: center;
  &:hover {
    opacity: 0.8;
  }
}