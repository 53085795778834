.chart-dropdown {
  height: 32px;
  border-radius: 6px;
  background-color: white;
  font-size: 12px;
  
  .dropdown-label {
    padding: 6px 10px;
    color: #404040;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
  .chev-down-icon{
    margin-left: 10px
  }
}

.rc-dropdown {
  ul {
    max-height: 300px;
    overflow: auto;
    padding: 5px;
  }
  .rc-dropdown-menu-item:hover {
    background-color: #FFF4D9;
  }
}

.radial-chart-container{
  padding-top: 100px;
}
