@import '../../../../styles/constants.scss';

.autocomplete-container {
  margin-bottom: 16px;
  position: relative;
  width: 50%;
}

.autocomplete-label {
  display: block;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.autocomplete-root {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  border-radius: 8px;
  color: #9DA8B7;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0,0,0, 0.05);
  display: flex;
  gap: 5px;
  overflow: hidden;
  width: 100%;
}

.autocomplete-root:hover {
  border-color: #3399FF;
}

.autocomplete-input {
  font-size: 16px;
  font-family: Jost;
  color: #000000;
  padding: 12px 16px;
  background-color: $gray_0;
  width: 100%;
  border: 1px solid $gray_4;
  border-radius: 6px;
  outline: none;
  &:focus {
    outline: none;
    border: 2px solid $primary;
  }
}

.autocomplete-listbox {
  font-size: 16px;
  font-family: Jost;
  color: #000000;
  box-sizing: border-box;
  padding: 6px;
  margin: 54px 0;
  width: 100%;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  max-height: 300px;
  z-index: 1;
  position: absolute;
  background: #fff;
  border: 1px solid #DAE2ED;
  box-shadow: 0px 2px 3px rgba(0,0,0, 0.05);
}

.autocomplete-option {
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: default;
}

.autocomplete-option:hover {
  cursor: pointer;
  background-color: #DAECFF;
  color: #003A75;
}

.error-case-highlight {
  font-size: 16px;
  font-family: Jost;
  padding: 12px 16px;
  background-color: $gray_0;
  width: 100%;
  border-radius: 6px;
  outline: none;
  border: 2px solid #FF6961;
  color: #FF6961;
}