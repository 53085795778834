$SUCCESS_LIGHT: #d6f4de;
$GRAY_FOUR: #dedede;
$SUCCESS: #56cd9d;
$BORDER_COLOR: lightgray;

.input_container {
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    width: 320px;
  }
}
.kpi_popover_container {
  position: relative;
  width: 400px;
  min-height: 400px;
  padding: 30px 0;
}
.select_kpi {
  min-width: 140px;
  height: 35px;
  border: 1px solid $BORDER_COLOR;
  border-radius: 5px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  cursor: pointer;
}
.kpis_container {
  margin-top: 25px;
  min-width: 350px;
  max-height: 350px;
  overflow: hidden;
  overflow-y: scroll;
  .single_kpi {
    display: flex;
    font-weight: 400;
    align-items: center;
    padding: 15px;
    width: 100%;
    height: 50px;
    font-size: 12px;
    border-top: 1px solid $GRAY_FOUR;
    cursor: pointer;
    transition: all 300ms;
  }
  .selected_kpi {
    display: flex;
    align-items: center;
    background-color: $SUCCESS_LIGHT;
    position: relative;
  }
  .green_circle {
    position: absolute;
    top: 40%;
    right: 20px;
    width: 10px;
    height: 10px;
    background: $SUCCESS;
    border-radius: 50%;
  }
}
.kpis_container:last-child {
  border-bottom: 1px solid $GRAY_FOUR;
}
.kpis_container::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
