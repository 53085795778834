@import '../../../styles/constants.scss';

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideUp {
  0% {
    display: none;
  }
  100% {
    display: flex;
    // margin-bottom: 24px;
  }
}

.workOrders_table-loading-spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid $primary;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 1s linear infinite;
}

.workOrders_table-spinner-container {
  margin: 4px 0px;
  padding: 2px 8px;
  
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  transition: all 0.5s linear;
  animation: slideUp 0.5s linear forwards;
}

.workOrders_table-data-message {
  width: 100%;
  text-align: center;
  align-self: center;
}
