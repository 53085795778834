.on-click-dropdown {
  .dropdown-container {
    position: relative;
    display: inline-block;
    text-transform: capitalize;
    width: 95%;
    margin-left: 0px;
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #fff;
      box-shadow: 0 8px 16px 0 rgba(201, 201, 201, 0.5);
      z-index: 15;
      font-weight: 100;
      margin-top: 8px;
      width: 100% !important;
      a,
      .option {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
      }
      .option {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .dropdown-container:hover {
    cursor: pointer;
    .dropdown-content {
      a:hover,
      .option:hover {
        background-color: #ebfcfd;
      }
    }
  }
  .hide {
    display: none;
  }
}
