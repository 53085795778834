.checklist-question_container {
  border-radius: 7px;
  border: 0.1rem solid #DEDEDE;
}
.notes-container{
  display: 'flex'; 
  justify-content: 'center'; 
  align-items: 'center';
}
.notesTitle{
  color: #2B323B;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.info_title{
  font-family: 'Jost', sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  color: #2B323B;
}
.info_decription{
  font-family: 'Jost', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #2B323B;
}
.info_contaier{
  display: flex;
  background-color: #FAFBFC;
  padding: 12px 16px;
  border-radius: 8px;
  align-items: center;
}
.info_right_container{
  margin-left: 8px;
}