@import "../../../../styles/constants";

.success-wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-flow: column nowrap;
}

.success-container {
  padding: 30px 6.2rem;
  width: 100vw;
}

.success-bg {
  width: 100%;
  height: 60%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: url("/assets/icons/after-password-background.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% auto;
}

.brand-name {
  color: #000000;
  font-size: 12px;
  letter-spacing: 3.9px;
  line-height: 14px;

}

.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

a.contact-button {
  height: 34px;
  width: 100px;
  border-radius: 17px;
  background-color: #C00000;
  background: $full-color-background;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.53px;
  line-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.contact-button:hover, a.contact-button:active {
  background: $full-color-background;
  color: #FFFFFF !important;
}

.password-reset-main-container {
  padding-left: 115px;
  padding-top: 65px;
}

.title-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.title {
  font-size: 44px;
  font-weight: bold;
  letter-spacing: -1.67px;
}

.font-bold {
  font-weight: bold;
}

.main-text {
  margin-top: 45px;
  font-size: 18px;
  letter-spacing: -0.68px;
}

a.app-button {
  margin-top: 25px;
  height: 34px;
  width: 140px;
  border-radius: 6px;
  background: $full-color-background;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.53px;
  line-height: 26px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
}

a.app-button:hover {
  color: #FFFFFF !important;
}

.link{
  color: $primary-blue;
  text-decoration: underline;
  background-color: transparent;
  display: inline;
  cursor: pointer;
}

.link:hover{
  color: $primary;
}

.title > p{
  display: inline;
}

.main-text > p{
  display: inline;
}

@media screen and (min-width:479px) and (max-width:768px){
  .success-container {
    padding: 30px 40px;
  }
  .success-container .password-reset-main-container {
    padding-left: 50px;
  }
}

@media screen and (max-width:480px){
  .success-container {
    padding: 30px 20px;
  }
  .success-container .password-reset-main-container {
    padding-left: 0px;
  }
  .success-container .title {
    font-size: 34px;
  }
}