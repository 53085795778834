@import '../../../../styles/constants.scss';

.user_assignment-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  }
  
.user_assignment-droppables-container {
  margin: 8px 0px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  max-height: calc(100% - 60px);
}

.user_assignment-right-droppables-container {
  display: flex;
  width: 49%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: calc(100% - 60px);
}
.user_assignment-checklist-droppable-container {
  margin: 0px 0px 8px 0px;
  padding: 8px;
  border-radius: 4px;
  background-color: #FAFAFA;
  p {
    font-size: 14;
    font-family: 'Outfit', sans-serif;
    color: #878787;
    align-items: center;
    display: flex;
    img {
      margin-right: 4px;
    }
  }
}

.user_assignment-checklist-droppable-container-half-width {
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

.user_assignment-user-list-card {
  padding: 4px;
  margin: 6px 0px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user_assignment-icon-text-row-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 4px;
  p {
    color: #000;
  }
}

.user_assignment-checklist-radio-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
  border-radius: 24px;
  &:hover {
    background-color: #F7F7F7;
  }
  &:active {
    background-color: #F0F0F0;
  }
}

.user_assignment-supervisor-border-box {
  border: 1px solid $primary;
  outline: none;
  box-shadow: 0px 0px 0px 2px #E2E0FE;
}

.user_assignement-user-not-available {
  opacity: 0.5;
}

.user_assignment-available-users-container {
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.user_assignment-search {
  input {
    width: 100%;
    border: 0px solid rgb(236, 239, 244);
    border-radius: 5px;
    background-color: #FAFAFA;
    padding: 10px;
    font-size: 1em;
    outline: none;
    margin: 0px;
  }
}
.user_assignement-user-company-name {
  opacity: 0.5;
  font-size: 12px;
  align-self: flex-end;
  padding: 1px 0px;
  margin: 0px 4px;
}