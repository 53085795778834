$BLACK: #000;
$WHITE: #FFF;
$PRIMARY: #C00000;
$PRIMARY_LIGHT: #FFE6E6;
$PRIMARY_DARK: #45120F; // usually used for headers
$DEFAULT: #F6EFEF;
$DEFAULT_DARK: #B18B8B; // usually used for subheaders
$COMPLEMENTARY: #F39C1F;
$COMPLEMENTARY_LIGHT: #FFEACC;
$COMPLEMENTARY2: #DA5926; // ORANGE
$DANGER: #EF5681;
$DANGER_LIGHT: #FFD9E3;
$SUCCESS: #56CD9D;
$SUCCESS_LIGHT: rgba(214, 244, 222, 1);
$DIVIDERS: #F8E4E4;
$BORDERS: #FBDDDD;
$INPUT_BACKGROUND: #FFFAFA; // input backgrounds such as dropdowns
$BACKGROUND: #FAF5F5; // 2 purposes: backgrounds and some default buttons.
$BUTTON_BACKGROUND: #F2F2F2;
$BUTTON_TEXT: #808080;
