
.actionButton__main{
  padding: 8px 12px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  outline: none;
  border: none;
  cursor: pointer;
}