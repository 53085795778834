// input[type=file]::file-selector-button {
//     margin-right: 20px;
//     border: none;
//     background: #6C63FF;
//     padding: 10px 20px;
//     border-radius: 10px;
//     color: #fff;
//     cursor: pointer;
//     transition: background .2s ease-in-out;
//   }
  
//   input[type=file]::file-selector-button:hover {
//     background: #6C63FF;
//   }

.spinner {
    display: inline-block;
    width: 20;
    height: 20;
    border: 3px solid rgba(#000000, 0.15);
    border-top: 3px solid #3169cc;
    border-radius: 50%;


    -webkit-transition-property: -webkit-transform;
    -webkit-transition-duration: 1.1s;
    -webkit-animation-name: rotate;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

    -moz-transition-property: -moz-transform;
    -moz-animation-name: rotate;
    -moz-animation-duration: 1.1s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;

    transition-duration: 1.1s;
    transition-property: transform;
    animation-name: rotate;
    animation-duration: 1.1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @-webkit-keyframes rotate {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
      }
    }

    @-moz-keyframes rotate {
      from {
        -moz-transform: rotate(0deg);
      }
      to {
        -moz-transform: rotate(360deg);
      }
    }

    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }