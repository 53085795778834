@import "../../../../styles/constants.scss";

.assetsInfo__container{
  display: flex;
  align-items: start;
  width: calc(100% - 180px);
  margin-left: 180px;
  padding: 24px 10% 24px 0;
  position: relative;
}
.assetsInfo__topbar{
  display: flex;
  align-items: center;
  gap: 12px;
}
.assetsInfo__topbar_tools{
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  width: 75%;
}
.assetsInfo__backButtonContainer {
  display: flex;
  gap: 12px;
  padding: 24px 12px 16px 12px;
  justify-content: center;
  align-items: center;
  color: $primary;
  font-weight: 500;
  margin-right: 5%;;
}
.assetsInfo__name{
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin-right: 86px;
}
.assetsInfo__borderButton{
  padding: 12px 16px 12px 16px;
  border-radius: 4px;
  border: 1px solid #D6D6D6;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
}
.assetsInfo__label{
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; 
  color: #808080;
  margin-bottom: 6px;
}
.assetsInfo__info{
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #2B323B;
}
.assetsInfo__info_container{
  padding: 24px 24px 24px 0px;
  display: flex;
  gap: 36px;
}
.assetsInfo__info_item{
  margin: 12px 0;
}
.assetsInfo__overlay_p{
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #808080;
}
.assetsInfo__overlay_head{
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 9px 24px 24px 24px;
}
.assetsInfo_qr_container{
  padding: 16px 32px 16px 32px;
  border-radius: 12px;
  border: 1px solid #DEDEDE;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-bottom: 20px;
}
.assetsInfo__download{
  display: flex;
  align-items: center;
  margin: 12px;
  gap: 18px;
}

.assetsInfo__avatar{
  margin-right: 16px;
  width: 88px;
  height: 88px;
  border: 2px solid #2B323B;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  position: relative;
  overflow: hidden;
  div{
    padding: 4px;
    background: #F2F2F2;
    border-radius: 100%;
    position: absolute;
    bottom: -2px;
    right: 4px;
    cursor: pointer;
  }
}