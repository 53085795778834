.KPI {
  font-weight: 500;
  border-top: 1px solid #DEDEDE;
  padding-right: 23px;
  display: flex;
  justify-content: space-between;
}

.badge {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #56CD9D;
}