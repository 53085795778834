.list-item {
  width: 348px;
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  &:hover {
    background-color: rgba(81, 114, 128, 0.116);
  }
}

.selected {
  background-color: #D6F4DE;
  font-weight: 500;
}

.filter-headers {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;;
}

.header-border {
  border-bottom: 1px solid #DEDEDE
}