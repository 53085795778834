.signatureContainer{
  display: flex;
  flex-direction: column;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #F2F2F2;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(#ffff, 0.15) !important;
  border-top: 3px solid #F2F2F2 !important;
  border-radius: 50%;

  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 1.1s;
  -webkit-animation-name: rotate;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-transition-property: -moz-transform;
  -moz-animation-name: rotate;
  -moz-animation-duration: 1.1s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  transition-duration: 1.1s;
  transition-property: transform;
  animation-name: rotate;
  animation-duration: 1.1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @-webkit-keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-moz-keyframes rotate {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}