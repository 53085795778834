@import "../../../../styles/constants.scss";

.page-container{
  height: 100vh;
  background-color: white;
}

.assetTypeHeader-container {
  width: 100%;
  padding: 16px 0px 8px;
  background-color: $primary-30;
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 10,
}
.assetType_header-back-and-title-container {
  width: 72%;
  display: flex;
  padding-left: 40px;
  flex-direction: row;
  align-items: flex-end;
  background-color: transparent;
}
.assetTypeHeader__back {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 10px;
  color: $primary;
  gap: 10px;
  margin-bottom: 18px;
}
.assetTypeHeader__button-container {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-right: 30px;
  padding-bottom: 12px;
}
.assetTypeHeader-action-button-done {
  width: 100px;
  height: 40px;
  background-color: #6c63ff;
  color: #fff;
  font-size: 16px;
  flex-direction: row;
  justify-content: space-around;
  font-weight: 500;
  align-items: center;
}
.assetType__edit-container {
  display: flex;
  background-color: $gray_0;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  justify-content: center;
}

.assetType__edit-container1 {
  flex: 1;
  background-color: $gray_0;
  max-width: 900px;
  padding-top: 40px;

  button {
    border-radius: 4px;
    padding: 12px 16px;
    margin-top: 40px;
    font-size: 12px;
    font-weight: 700;
  }
}

.assetType_edit {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #2b323b;
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.assetType__edit-container1 > p {
  margin-top: 30px;
}

.assetType_edit-cards {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.assetType_edit-cards:nth-last-child(1) {
  border-bottom: 1px solid $gray_3;
}

.assetType_edit-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  padding: 16px;
  padding-left: 20px;
  border: 1px solid $gray_3;
}

.assetType_edit-card > span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $gray_9;
}

.assetType_edit-card > div {
  display: flex;
  gap: 10px;
  align-items: center;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $gray_9;
  }
}

.assetType_edit-card1{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  padding: 16px;
  padding-left: 20px;
  border: 1px solid $gray_3;
}

.assetType_edit-card1 > span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $gray_9;
}

.assetType_edit-card1 > div {
  display: flex;
  gap: 10px;
  align-items: center;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $gray_9;
  }
}

.newfield-overlay{
  max-width: 632px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  background-color: #FFFFFF;
  justify-content: center;
  align-items: flex-start;
  margin-top: 200px;
  padding: 24px;
  border: 1px solid #DEDEDE;
  border-radius: 8px;
}

.input-field_wrapper{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0;
  margin-top: 15px;
}

.dropdown-container_wrapper{
  position: relative;
  display: flex;
  margin-left: 12px;
  align-items: center;
  width: 30%;
}
.dropdown-options{
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  width: 180px;
}

.dropdown-option {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  gap: 10px;
}

.asset_type-input-container{
  display: flex;
  width: 100%;
  padding: 14px 0px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.dropdown-option:hover {
  background-color: #F0F0F0;
}

.dropdown-option:active {
  background-color: #F0F0F0;
}

.field-dropdown{
  position: absolute;
  right: 35px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  width: 180px;
  margin-top: 95px;
  margin-bottom: 40px;
  z-index: 99;
}

.assets_type-menu-item-div{
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.asset-loader{
  margin-top: 264px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}