@import '../../../../styles/constants.scss';

.routines_dropdown_for_excel_download__overlay_head {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  @media only screen and (max-width: 650px) {
    display: none;
  }
}

.routines_dropdown_for_excel_download_title {
  font-weight: 500;
  color: #000000;
  font-size: 1.25rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Plus Jakarta Sans';
}

.routines_dropdown_for_excel_download_sub-title {
  color: $gray_10;
  font-size: 0.85rem;
  margin: 0.1rem 0rem 1rem 0rem;
  text-align: center;
}

.routines_dropdown_for_excel_download_save-button {
  margin-top: 12px;
  background-color: $primary;
  border: none;
  border-radius: 4px;
  color: white;
  display: flex;
  height: 2rem;
  width: 7rem;
  justify-content: center;
  align-items: center;
}

.routines_dropdown_for_excel-routines-list-container {
  max-height: 50vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.routines_dropdown_for_excel-routines-list-container::-webkit-scrollbar { 
  display: none;
}

.routines_dropdown_for_excel-routine-list-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  font-family: 'Outfit';
  padding: 0.2rem;
  border-radius: 0.4rem;
}

.routines_dropdown_for_excel-routine-list-item:hover {
  background-color: $gray_1;
}

.routines_dropdown_for_excel-routine-list-item:active {
  background-color: $gray_3;
}

.routines_dropdown_for_excel_download_save-button-disabled {
  background-color: #6C63FF40;
}