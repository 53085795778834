.link-dropdown {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding:4px;
  box-sizing: border-box;

  &:hover {
    background: #DEDEDE;
    border-radius: 100px;
  }

  &.clicked {
    background: #E2E0FF !important;
    border-radius: 100px;
  }
}

.settings {
  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #2B323B;
    text-transform: unset !important;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 0px;

      g {
        fill: #2B323B;
      }
    }
  }
}
.userHeader{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.userEmailHeader{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.userImage{
  width: 48px;
  height: 48px;
  border-radius: 48px;
  object-fit: fill;
}
.userInfo{
  // overflowWrap: 'anywhere',
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-left: 16px;
}
.userEmail{
  font-family: 'Jost';
  font-size: 16px;
  font-weight: 400;
  text-transform: lowercase;
}
.userName{
  font-family: 'Plus Jakarta Sans';
  font-size: 15px;
  font-weight: 700;
}
.userCompanyLogoWrap{
  border-radius: 8px;
  background-color: #F7F7F7;
  margin-top: 16px;
  padding: 8px 12px;
  display: inline-block;
}
.userCompanyLogo{
  width: 109px;
  height: 20px;
  object-fit: fill;
}
.userCompanyDetailsWrap{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
}
.userCompanyNameLogo{
  width: 24;
  height: 24px;
  object-fit: fill;
  margin-right: 8px;
}
.userCompanyName{
  font-family: 'Jost';
  font-size: 16px;
  font-weight: 400;
}
.userPositionWrap{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}
.logout{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.userImageMain{
  width: 40px;
  height: 40px;
  border-radius: 40px;
  object-fit: fill;
}
