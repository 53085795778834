@import '../../../../styles/constants.scss';

.add_asset-page-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  overflow: scroll;
}
.asset-builder__container {
  display: flex;
  padding: 32px 0px 0px 400px;
  flex-direction: row;
  align-items: flex-start;
  // flex: 1;
  // background-color: red;
}
.asset-builder_required-inputs-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.asset-builder_required-inputs-wrapper-adjacent {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  align-items: flex-start;
  // gap: 24px;
}
.asset-title{
  font-size: 24px; 
  font-weight:'bold';
}
.buscar-style{
  color: #6C63FF;
  font-weight: 600;
}
.asset-builder__flex-row{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items:center;
  gap: 16px;
}
.backButtonContainer {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid  #D6D6D6;
  background: #FFF;
}
.searchFolder{
  display: flex;
  flex-direction: row;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid  #DEDEDE;
  background:  #F2F4F7;
}
.add_asset-pop-up-input-container {
  padding-left: 0px; 
  margin-top: 8px;
  display: flex;
  width: 100%;
  padding: 0px 8px 2px 8px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.add_asset-pop-up-input-container{
  padding: 0px !important;
}
.add_asset-quesion_popup-input-style {
  border-radius: 6px;
  border: 1px solid #DEDEDE;
  background: #FAFAFA;
  color:#404040;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 100%;
  padding: 18px 14px 18px 14px !important;
  height: 45px; 
  width: 345px !important;  
  background-color: '#fff';
  &:focus {
    border: 1px solid #6C63FF;
    box-shadow: 0px 0px 0px 4px #E2E0FE;
}}
.add_asset-dropdownInputStyle {
  border: 2px solid;
  box-shadow: none; 
  border-color: rgb(236, 239, 244); 
  margin-top: 10px;
  border-radius: 5px;
  width: 345px; 
  background-color: '#fff'; 
}
.add_asset-dropdown-container {
  display: flex;
  margin-left: 0px !important;
  align-items: center;
  width: 100% !important;
  /* width: 30%; */
}
.button-class{
  padding-left: 400px; 
  margin-top: 65px; 
  width: 100%; 
  height: 40px; 
  font-size: 14px;
  font-weight: 400;
}
.unique-subtitle{
  color:rgba(67, 67, 67, 0.60);
  /* Body/BODY-XS */
  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.add_asset-date-wrapper {
  // padding: 7px 0px 7px 0px;
  // border: 1px solid #DEDEDE;
  // border-radius: 5px;
  // width: 180%;
  // overflow: hidden;
}
.add_asset-error-border {
  border: 1px solid #E92B36;
  background-color: red;
}
.add_asset-imageTextContainer {
  width: auto;
  height: auto;
  align-self: center;
  margin-top: 8px;
}
.add_asset-attribute-input {
  &:focus {
    border: 1px solid #6C63FF;
    box-shadow: 0px 0px 0px 4px #6C63FF;
  }
}