@import "../../styles/color-codes";
.overlay {
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(184,191,204,0.3);
  position: fixed;
  top: 0;
  left: 0;
}

.modalContainer {
  background-color: $WHITE;
  z-index: 101;
  height: 60vh;
  width: 60vw;
  position: fixed;
  left: 20vw;
  top: 20vh;
  box-shadow: 0 6px 10px 0 rgba(207,207,207,0.5);
  border-radius: 12px;
  padding: 20px 35px;
  overflow: auto;
}
