@import '../../../styles/constants.scss';
.wo_page-container{
  height: 100vh;
  background-color: white;
  font-family: 'Outfit';
}
.wo__container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 180px);
  margin-left: 180px;
  padding: 12px 12px 12px 12px;
  overflow-y: scroll;
  max-height: calc(100vh - 40px);
}

.wo__cards-row{
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
}
.wo__flex-row{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  // margin-top: 1rem;
}
.wo__no-checklist-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  margin: 4rem 0;
}

.wo__no-checklist-h{
  font-weight: 600;
  font-size: 17px;
  font-family: Jost;
  line-height: 24px;
  color: $primary-dark;
}

.wo__no-checklist-p{
  font-weight: 400;
  font-size: 16px;
  font-family: Jost;
  line-height: 20px;
  color: $primary-dark;
}

.wo__no-checklist-margin-hor-16{
  margin: 16px 0;
}
.wo__scroll-view{
  // overflow-y: scroll;
  // height: 65vh;
}
.wo__scroll-view::-webkit-scrollbar{
  display: none;
}
.shareContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#shareStyle:hover {
  border-radius: 20px;
  background: var(--fontcolorsgray-3, #F0F0F0);
}
#shareStyle:active {
  border-radius: 20px;
  background: var(--fontcolorsgray-4, #DEDEDE);
}
.wo_dashboard-option-menu {
  div {
    margin-bottom: 4px;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    // background-color: red;
    margin-left: 6px;
    margin-right: 24px;
    cursor: pointer;
    img {
      height: 18px;
      width: 18px;
      margin-right: 10px;
    }
  }
}
.wo_dashboard-menu-item {
  &:hover {
    background-color: $gray_1;
  }
}
.wo__menu_editTitle{
  font-weight: 400;
  font-size: 16px;
  font-family: Jost;
  line-height: 20px;
  color: #2B323B;
}
.wo__menu_deleteTitle{
  font-weight: 400;
  font-size: 16px;
  font-family: Jost;
  line-height: 20px;
  color: #E92B36
}
.wo__title{
  font-weight: 400;
  font-size: 16px;
  font-family: Jost;
  line-height: 24px;
  color: #404040;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.wo__answered-question-title{
  font-weight: 400;
  font-size: 16px;
  font-family: Jost;
  line-height: 24px;
  color: #404040;
}
.wo__total-question-title{
  font-weight: 400;
  font-size: 14px;
  align-self: center;
  font-family: Jost;
  color: #40404080;
}
.wo__answered-question-title{
  font-weight: 400;
  font-size: 16px;
  font-family: Jost;
  line-height: 24px;
  color: #404040
}
.wo__checklist-title{
  font-weight: 400;
  font-size: 16px;
  font-family: Jost;
  line-height: 24px;
  color: #6C63FF
}
.wo__id{
  font-weight: 500;
  font-size: 17px;
  font-family: Jost;
  line-height: 24px;
  color: #404040
}
.wo_dashboard-menu-item-div{
  display: flex;
  justify-content: center;
  align-items: center;
}
.wo__deleteMoal-title {
  font-family: 'Outfit', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 4px;
  color: #2B323B;
}
.wo__deleteMoal-description {
  font-family: 'Jost', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
  color: #2B323B;
}
.wo__deleteMoal-button_container{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}
.wo__deleteMoal-cancel_button{
  color: #404040;
  border-radius: 4px;
  border: 1px solid #D6D6D6;
  border-color: #D6D6D6;
  background-color: #FFFFFF;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 8px;
}
.wo__deleteMoal-delete_button{
  color: #FFFFFF;
  border-radius: 4px;
  background-color: #E92B36;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}
.wo__cancelModal-send_button{
  color: #FFFFFF;
  border-radius: 4px;
  background-color: #6C63FF;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}
.wo__delete-loader{
  margin-top: 45px;
  // margin: 0px 0px 45px 0px;
}
.wo__tabs {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Plus Jakarta Sans';
  background-color: transparent;
}
// .wo__tab{
//   .tabs .tab-item-selected{
//     border-bottom: 2px solid $primary;
//     color: $primary;
//   }
//   .tabs{
//     box-shadow: none !important;
//   }
//   .tab-item{
//     width: 76px;
//   }
//   .wo__not-selected-tab{
//     border-bottom: 0px;
//     color: #878787
//   }
// }
.wo__question-wrap{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}
.wo__menuContainer{
  display: flex;
}
.wo__editIcon{
  margin-right: 6px;
  padding: 5px;
  border-radius: 4px;
  &:hover {
    background-color: #F2F2F2;
  }
  &:active {
    background-color: #DEDEDE;
  }
}
.wo__mailIcon{
  margin-right: 6px;
  padding: 5px;
  border-radius: 4px;
  &:hover {
    background-color: #F2F2F2;
  }
  &:active {
    background-color: #DEDEDE;
  }
}
.wo__deleteIcon {
  margin-right: 6px;
  padding: 5px;
  border-radius: 4px;
  &:hover {
    background-color: #F2F2F2;
  }
  &:active {
    background-color: #DEDEDE;
  }
}
.opacity{
  opacity: 0.5;
}
.wo__cancellation-reason-input {
  height: 80px;
  padding: 8px;
  border: 1px solid #00000020;
  border-radius: 4px;
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.wo_tab-send-email-loader-div {
  padding: auto;
  margin-bottom: 22px;
}
.MuiTableCell-root {
  padding: 0px;
}
.wo_action_icon {
  width: 18px;
  height: 18px;
  margin: 0px 4px 0px 4px;
  object-fit: contain;
}

.workOrders_tabs-and-selector-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 24px;
  width: 100%;
}

.workOrders-view-type-selector-container {
  margin: 0px 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  img {
    margin: 0px 4px;
  }
}

.workOrders-view-mode-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  box-shadow: 0 2px 4px 0 #6C63FF90;
  border-radius: 24px 0px 0px 24px;
  border-right: $placeholder-color 1px solid;
  background-color: $valid_input_border;

  &:nth-child(2) {
    border-radius: 0px 24px 24px 0px;
    border-right: none;
  }
}

.workOrders-active-view-mode {
  background-color: #6C63FF;
}

.workOrders-view-type-selector {
  width: 100%;
  padding: 0px;
}

.workOrder_action-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.workOrders-invert-opacity {
  opacity: 0.2;
}