.on-click-dropdown {
  .filter-dropdown-container {
    position: relative;
    display: inline-block;
    .filter-header{
      min-width: 100px;
      max-width: 100px;
      text-align: center;
      overflow: hidden;
    }
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #fff;
      width: 360px;
      box-shadow: 0 8px 16px 0 rgba(201, 201, 201, 0.5);
      z-index: 15;
      margin-top: 8px;
      a,
      .option {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
      }
      .option {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .hide {
    display: none;
  }
}

.search-bar{
  display: flex;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 15px;
  max-width: 325px;
}

.no-result {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  font-weight: 500;
}

.icon_search {
  margin-right: 10px;
}

.stash{
  display: none;
}
