@import "../../../styles/constants.scss";

.checklist-builder__container {
  display: flex;
  flex-direction: column;
  padding: 24px 24px 24px 210px;
  width: 100vw;
  height: auto;
  margin-left: 0px;
  position: relative;
  background-color: #fff;
}
.checklist-builder__cards-row{
  display: flex;
  flex-wrap: wrap;
}
.checklist-builder__flex-row{
  display: flex;
  align-items: center;
  margin: 1rem 0;
}
.no-margin {
  margin: 0;
}
.checklist-builder__flex-justify-between{
  justify-content: space-between;
}
.checklist-builder__flex-justify-start{
  justify-content: flex-end;
}
.checklist-builder__w-half{
  width: 50%
}
.checklist-builder__w-80{
  width: 80%
}
.checklist-builder__w-full{
  width: 100%;
}
.checklist-builder__input-row{
  display: flex;
  align-items: center;
  p{
    font-size: 16px;
    font-weight: 600;
    margin-right: 12px;
    white-space:nowrap;
  }
}
.checklist-builder__row-style {
  display: flex;
  gap: 10px;
}
.checklist-container{
  display: flex;
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;
}
.checklist-description-container{
  width: 55%;
  align-self: center;
  // margin-top: 12px;
  display: flex;
  flex-direction: row;
  // margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
}
.header-description-v1 {
  color: #2B323B;
  font-size: 16px;
  font-style: normal;
  width: 100%;
  font-weight: 400;
  line-height: 20px;
  // margin-right: 8px;
}
.checklist-toggle-container{
  display: flex;
  // padding: 12px 16px;
  align-items: center;
  width: 55%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0px 0px 16px 16px;
  background-color: #F2F4F7;
  margin-left: 22%;
}
.checklist-header-container{
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  // width: 100%;
  max-width: 100%;
}
.clickable-area {
  flex: 1;
  max-width: 80%;
  height: 50px;
}
.down-arrow-style{
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 88px;
  background:#56CD9D;
}
.header-text{
  color: #2B323B;
  font-size: 15px;
  font-style: normal;
  max-width: 100%;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.section_title{
  color: #2B323B;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
  border-radius: 4px;
  width: 100%;
  &::placeholder {
    color: #2B323B;
    font-weight: 700;
  }
}
.section-container{
  color: #B5B5B5;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 38px;
  &:hover {
    .add_answers-section-actions-wrapper {
      opacity: 1;
    }
    .duplicate_section_container {
      opacity: 1;
    }
  }
}
.question-text-style{
  color:#B5B5B5;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.question-box {
  display: flex;
  width: 55%;
  align-items: flex-start;
  color: #000;
  margin-left: 22%;
  font-size: 15px;
  padding: 0px 20px 0px 20px;
  align-self: center;
  margin-top: 22px;
  margin-bottom: 14px;
  ::placeholder {
    color: #B5B5B5;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: #B5B5B5;
  }
  ::-ms-input-placeholder {
    color: #B5B5B5;
  }
}
.input-container{
  display: flex;
  width: 70%;
  padding: 14px 0px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}
.input-style{
  border-radius: 6px;
  border: 1px solid #DEDEDE;
  background: #FAFAFA;
  color:#404040;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 100%;
  padding: 12px 14px 12px 14px;
  &:focus {
    border: 1px solid #6C63FF;
    box-shadow: 0px 0px 0px 4px #E2E0FE;
  }
}
.dropdown-container{
  display: flex;
  margin-left: 12px;
  align-items: center;
  width: 30%;
}
.checklist-question-popup {
  display: flex;
  padding: 8px 14px 8px 14px;
  width: 100%;
  border: 1px solid #DEDEDE;
  border-radius: 6px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  p {
    overflow: hidden;
    text-overflow: clip;
  }
}
.questions-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 38px;
}
.add_answers-checklist-bit-input-option {
  padding: 8px 16px;
  background-color: $gray_0;
  width: 100%;
  border: 1px solid $gray_4;
  border-radius: 6px;
  outline: none;
  &:focus{
    border: solid 1px $primary;
    box-shadow: 0px 0px 0px 4px #E2E0FE;
  }
}
.add_answers-boundary-values-input {
  padding: 12px 16px;
  background-color: $gray_0;
  width: 49%;
  border: 1px solid $gray_4;
  border-radius: 6px;
  outline: none;
  margin: 4px 0px 18px 0px;
  &:focus{
    border: solid 1px $primary;
    box-shadow: 0px 0px 0px 4px #E2E0FE;
  }
}
.checklist-builder_input_error {
  padding: 12px 16px;
  background-color: $gray_0;
  width: 99%;
  color: #FF6961;
  border: 1px solid $gray_4;
  border-radius: 6px;
  outline: none;
  &:focus{
    outline: none;
    box-shadow: 0px 0px 0px 4px #E2E0FE;
  }
}
.checklist-builder_text-area{
  border-radius: 4px;
  background-color: $gray_0;
  border: 1px solid $gray_4;
  padding: 14px 12px;
  outline: none;
  width: 100%;
  &:focus{
    box-shadow: 0px 0px 0px 4px #E2E0FE;
  }
}
.checklist-builder__section-container{
  background-color: $gray_1;
  border: none;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.checklist-builder__item-container {
  width: 100%;
}
.checklist-builder__item-card{
  border: none;
  border-radius: 6px;
  margin-bottom: 8px;
  background-color: #fff;
}
.checklist-builder_relative{
  position: relative;
}
.checklist-builder_delete-icon{
  cursor: pointer;
}
.checklist-builder_three-dots{
  cursor: pointer;
}
.checklist-builder_section_header{
  background-color: $successLight;
  border-radius: 8px 8px 0px 0px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
}
.checklist-builder_item_header{
  background-color: $primary-dark;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $secondary_back_light;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #DEDEDE;
}
.checklist-builder_padding_32{
  padding: 2rem;
}
.checklist-builder_padding_16{
  padding: 1rem;
}
.checklist-builder_padding_8{
  padding: 8px;
}
.checklist-builder_margin_8{
  margin: 8px;
}
.checklist-builder_add-item-button{
  color: $gray_10 !important;
  background-color: $gray_0 !important;
  border: 1px solid $gray_4 !important;
  font-size: 12;
  font-weight: 500;
  &:hover {
    background-color: $hover-grey !important;
  }
}
.checklist-builder_bit_header{
  background-color: $primary_back_list;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px 8px 0px 0px;
  color: #000;
}
.checklist-builder__answer-card{
  border: none;
  border-radius: 6px;
  margin-bottom: 8px;
  background-color: $primary_back_list;
}
.checklist-builder__answer-container{
  padding: 0.5rem;
}
.checklist-builder__select-option{
  background: #FFFFFF;
  border: 1px solid $gray_4;
  border-radius: 6px;
  width: 100% !important;
}
.checklist-builder_select-keyboard-type{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 1rem 0;
}
.checklist-builder__switch-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
}
.checklist-builder_select-keyboard-type-active{
  padding: 4px 16px 4px 16px;
  gap: 2px;
  background: $primary-30;
  border: 1px solid $primary;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48%;
  p {
    width: 100%;
    text-align: center;
  }
}
.checklist-builder_select-keyboard-type-disabled{
  padding: 4px 16px 4px 16px;
  gap: 2px;
  background: #fff;
  border: 1px solid $gray_3;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48%;
  p {
    width: 100%;
    text-align: center;
  }
}
.checklist-builder__keyboard-type{
  width: 100%;
  p{
    color: $gray_9 !important;
    margin: 8px 0;
  }
}
.checklist-builder__button-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  margin-bottom: 22px;
  gap: 8px;
}
.checklist-builder_scroll-view{
  overflow-y: scroll;
  height: 74vh;
}
.error{
  color: $error;
  margin-bottom: 8px;
}
.checklist-builder_logic{
  display: flex;
  // background-color: red;
  padding: 0 16px;
  flex-direction: column;
}
.checklist-builder_quit-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
.checklist-builder_quit{
  color: $primary;
  padding: 8px 12px;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: $primary_back_list;
  &:hover {
    background-color: $gray_0;
    cursor: pointer;
  }
}
.checklist-builder_h3{
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: $primary-dark;
}
.checklist-builder_logic_select_row{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  // grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 8px;
  margin: 8px 0;
  background-color: red;
  align-items: center;
}
.MuiFormGroup-root{
  flex-direction: row !important;
}
.addAnswerImageContainer{
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.imageTextContainer{
  width: 100%;
  align-self: center;
  margin-top: 24px;
  display: flex;
}
.imageTextContainerV1{
  width: 100%;
  align-self: center;
  margin-top: 24px;
}
.reUploadImage{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px 8px 10px;
  background: #FFFFFF;
  border: 1px solid #d1d3d1;
  border-radius: 6px;
  width: 100%;
}
.delete-image{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px 8px 10px;
  background: #FFFFFF;
  border: 1px solid #d1d3d1;
  border-radius: 6px;
  width: 100%;
  color: white;
}
input[type='radio']:after {
  background-color: #d1d3d1;
}
input[type='radio']:checked:after {
  background-color: $primary !important;
}
.checklist-builder_w-8{
  width: 8rem;
}
.checklist-builder_w-100 {
  width: 100%;
}
.MuiInput-underline:before{
  border: 0px !important
}
.add_answers-checklist-builder_select-dropdown{
  border-radius: 6px;
  border: 1px solid $gray_4;
}
.checklist-builder__add-logic-container{
  display: flex;
  background-color: #FFFFFF;
  width: 100%;
  gap: 8px;
  padding-bottom: 8px;
  overflow-y: scroll;
}
.checklist-builder__add-logic-bar-container{
  width: 20%;
  height: 95vh;
  background-color: #FAFBFC;
  padding: 24px 1vw 24px 24px;
  padding-right: 12px;
  overflow-y: scroll;
}
.checklist-builder__add-logic-bar{
  display: flex;
  gap: 12px;
  margin-bottom: 32px;
  align-items: center;
  justify-content: space-between;
}
.checklist-builder__logic-title{
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #2B323B;
  margin: 8px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.checklist-builder__logic-title-active{
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: $primary;
  margin: 8px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.dropdown{
  border: 1px solid $gray_4;
  box-shadow: 0px 0px 0px 4px #E2E0FE;
  border-radius: 4px;
}
.box-shadow-none {
  box-shadow: none;
  background-color: $gray_0;
}
.checklist-builder_logic_row{
  width: 100%;
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 8px;
  margin: 8px 0;
  align-items: center;
  justify-content: center;
}
.checklist-builder_plus-button{
  width: 3rem;
  padding: 8px 12px;
  background: #FFEDBD;
  font-weight: 700;
  font-size: 12px;
  border-radius: 4px;
  border: none;
  outline: none;
  margin: 16px 0;
}
.rounded-outline{
  border: 1px solid $gray_4;
  border-radius: 8px;
  margin-bottom: 16px;
}
.radio-row{
  display: flex;
  align-items: center;
}
.flex-center{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-top: 8px;
}
.sidebar {
  position: absolute;
  // top: 100px;
  left: 0px;
  z-index: 10;
  width: 15%;
  padding: 30px;
  // max-height: calc(100% - 100px);
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
}
.sidebar > h1 {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20;
  overflow: hidden;
  text-overflow: clip;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.item-outline {
  font-size: 12px;
  margin: 3px;
  margin-left: 10px;
  cursor: pointer;
}
.toggle_wrapper{
  background-color: #eae9e9;
  padding: 8px;
  border-radius: 8px;
  div{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.pill-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4px;
}
.pill {
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 2px 8px;
  margin-right: 5px;
  display: flex;
  align-items: center;
}
.cross-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 5px;
  font-size: 12px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cross-button:hover {
  background-color: #8EB7FF;
  color: white;
}
.checklist_builder_new_checklist {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
}
.checklist_question_container {
  width: 100%;
  margin-left: 22%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  &:hover {
    .checklist_add_answers-question-actions-wrapper {
      opacity: 1;
    }
    .checklist_add_answers-question-actions-wrapper-Icons {
      opacity: 1;
    }
    .input-style {
      box-shadow: 0px 0px 0px 4px #E2E0FE;
    }
  }
}
.question_input_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
  margin: 0px 0px 0px 0px;
}
.question_input_wrapper-v1 {
  display: flex;
  width: 55%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0px 0px 0px 0px;
}
.checklist_add_answers-question-actions-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 16px;
  padding: 0px 0px 0px 0px;
  opacity: 0;
  padding: 0px 20px 0px 20px;
  transition: opacity 0.2s ease-in-out;
}
.add_answer-horizontal-break {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  width: 57%;
  height: 1px;
  border: 1px solid #DEDEDE;
  border-bottom: none;
  border-left: none;
  border-right: none;
}
.checklist-builder_add-section-button{
  font-size: 12;
  font-weight: 500;
  margin-bottom: 12px;
  align-self: flex-start;
  margin-left: 22%;
  border: 1px solid #6C63FF;
  &:hover {
    background-color: #6C63FF;
    color: white;
  }
}
.react-beautiful-dnd-draggable {
  transition: transform 0.2s ease !important;
  cursor: none;
}
.add_answer-checklist-question-container {
  position: relative;
  width: 100%;
}

.add_answer-checklist-add-question-description {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: inherit;
  top: 2px;
  margin-bottom: 8px;
  left: 22%;
  width: 100%;
  height: 0px;
  padding: 0px 20px 0px 20px;
  p {
    font-size: 13px;
    font-size: 500;
    color: #2B323B;
  }
}
.add_answers-checklist-radio-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 8px;
  padding: 0px 10px 0px 10px;
  &:hover {
    background-color: #F7F7F7;
    border-radius: 4px;
  }
  &:active {
    background-color: #F0F0F0;
    border-radius: 4px;
  }
}
.add_answer-checklist-question-type-nested-checklist-container {
  width: 100%;
  padding: 0px;
}
.add_answer-nested-checklist-dropdown {
  width: 100%;
  display: flex;
  padding-bottom: 0px;
  border-radius: 6px;
  border: 2px solid #DEDEDE;
}
.add_answers-section-header-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.add_answers-section-actions-wrapper {
  padding: 0px 0px 0px 0px;
  opacity: 0;
  // padding: 0px 20px 0px 20px;
  transition: opacity 0.2s ease-in-out;
}
.add_answers-floating-question-logic-tag {
  width: 100%;
  left: 22%;
  margin-bottom: -12px;
  display: flex;
  flex-direction: row;
  padding-top: 4px;
  z-index: 1;
  justify-content: flex-start;
  align-items: center;
  margin-left: 28px;
  font-size: 12px;
  margin-top: 4px;
  color: #43434390;
}
.add_logic-logic-edit-wrapper {
  width: 60%;
}
.add_answers-section-drop-icon {
  transform: rotate(0deg);
  transition: transform 0.2s ease !important;
  &:hover {
    transform: scale(1.1);
  }
}
.add_answers-section-collapsed-icon {
  transform: rotate(-90deg);
  transition: transform 0.2s ease !important;
  &:hover {
    transform: scale(1.1);
  }
}
.add_answers-enable-repitition {
  color: #2B323B;
  font-size: 16px;
  font-weight: 400;
  margin-left: 6px;
}
.checklist_add_answers-question-actions-wrapper-Icons {
  position: absolute;
  left: 21%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  opacity: 0;
  padding: 0px 20px 0px 0px;
  transition: opacity 0.2s ease-in-out;
}
.add_answers-ai-suggestion-container {
  position: absolute;
  z-index: 1;
  top: 90px;
  // margin-left: 23%;
  // width: 100%;
  margin-bottom: 50px;
}
.checklist_add_logic-question-actions-wrapper {
  position: absolute;
  top: auto;
  // background-color: red;
  display: flex;
  z-index: 1;
  margin-left: 61%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  opacity: 0;
  padding: 0px 20px 0px 20px;
  transition: opacity 0.2s ease-in-out;
}
.add_logic-triggers-wrapper {
  width: 100%;
  &:hover {
    .checklist_add_logic-question-actions-wrapper {
      opacity: 1;
    }
  }
}
.add_logic-source-item-triggers-wrapper {
  padding: 6px 18px;
  background-color: #F2F4F7;
  border-radius: 4px;
  width: 90%;
}
.add_logic-source-item-wrapper {
  width: 100%;
  display: flex;
  margin-bottom: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.add_logic-affeted-item-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.select-and-or {
  margin: 0px;
  // background-color: red;
  padding: 0px;
  margin-left: -18px;
  max-width: 90px;
}
.duplicate_section_container{
  margin-left: 12px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.instruction_image_wrap{
  padding: 8px 16px 8px 16px;
  gap: 0px;
  border-radius: 4px;
  border: 1px solid #DEDEDE;
  display: flex;
  align-items: center;
  justify-content: center;
}
.instruction_image__replace_wrap{
  padding: 8px 16px 8px 16px;
  gap: 0px;
  border-radius: 4px;
  border: 1px solid #DEDEDE;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85%;
}
.instruction_image_delete_wrap{
  padding: 8px 16px 8px 16px;
  gap: 0px;
  border-radius: 4px;
  border: 1px solid #DEDEDE;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 15%;
}
.instruction_image_upload_title{
  font-family: 'Quicksand';
  font-size: 12px;
  font-weight: 700 !important;
  line-height: 16px;
  padding: 0px !important;
  align-self: center !important;
  margin-left: 6px;
  width: unset !important;
}
.instruction_image{
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
}
.instruction_image_v1{
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  border-radius: 4px;
  margin-left: 5px;
}
.instruction_image_v1_wrap{
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 38px;
  height: 38px;
}
.instruction_image_v1_modal_wrap{
  width: 100%;
  height: 100%;
}
.instruction_image_v1_modal{
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
}
.image_upload_wrap{
  align-content: center;
  margin-top: 20px;
}
.add_answers-boundary-values-input-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.flex-between {
  justify-content: space-between;
}
.add_answers-vertical-margin {
  margin: 4px 0px;
}
.add_answers-margin-top {
  margin-top: 12px;
}