.header {
  // height: 80px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #DAE0EB;
  display: flex;
  align-items: center;
  // padding: 12px 10px;

  .home-button-container {
    display: flex;
    align-items: center;
    margin-right: 16.5px;
    margin-left: 23px;
    cursor: pointer;

    .title {
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0;
      color: black;
      margin-left: 7px;
    }

    .icon-wrapper {
      border: 2px solid white;

      &.hovered {
        border: 2px solid #FBD025;
        border-radius: 6px;
      }
    }
  }

  .line {
    box-sizing: border-box;
    height: 45px;
    width: 1px;
    margin-left: 23.5px;
    margin-right: 23.5px;
    border: 1px solid #F2F2F2;
  }

  .info {
    display: flex;
    align-items: center;

    .refresh-icon {
      cursor: pointer;
    }

    p {
      margin-right: 16px;
      color: #878787;
    }

    &__dropdown {
      display: flex;
      flex-direction: column;
      font-size: 14px;

      &:hover {
        background-color: #F2F2F2;
        .full-content {
          .select {
            background-color: #F2F2F2 !important;
          }
        }
      }

      span {
        width: 100%;
        font-size: 9px;
        font-weight: bolder;
        padding: 5px 10px;
        margin-bottom: -11px;
        z-index: 2;
      }
    }

    &__dropdown--grey {
      background-color: #F2F2F2;
    }
  }

  .icons {
    margin-left: auto;
    margin-right: 30.56px;
    display: flex;
    align-items: center;

    .line {
      margin-left: 13.5px;
      margin-right: 13.5px;
    }

    .on-click-dropdown .dropdown-container .dropdown-content {
      right: -10px;
    }

    .notification-icon {
      margin-right: 2px;
    }
  }

  .select {
    padding: 5px 10px;
  }
}
.espectro-header{
  // position: sticky;
  top: 0;
  right: 0;
  left: 0;

  .on-click-dropdown .dropdown-container .dropdown-content {
    top: 47px;
    right: -10px;
    width: 280px !important;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 8px 16px 0 hsla(0,0%,78.8%,.5);
    z-index: 15;
    font-weight: 100;
    margin-top: 8px;
  }

  .dropdown-container:hover {
    cursor: pointer;
    .dropdown-content {
      a:hover,
      .option:hover {
        background-color: #F7F7F7;
      }
    }
  }
}
.espectro-header-flex-between{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
}
.dropdown-container{
  display: flex;
  margin-left: 0px;
  align-items: center;
  width: 30%;
}
.on-click-dropdown .dropdown-container {
  position: relative;
  display: inline-block;
  text-transform: capitalize;
  width: 100%;
  margin-left: 0px;
}