@import "../../../styles/constants.scss";

.assets-dashboard__container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 162px);
  margin-left: 162px;
  padding: 24px 24px 24px 48px;
  position: relative;
  background-color: white;
}

.assets-dashboard__cards-row{
  display: flex;
  flex-wrap: wrap;
}

.assets-dashboard_scroll-view{
  margin-top: 28px;
  overflow-y: scroll;
  height: 100vh;
}

.assets-dashboard__flex-row{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
}

.assets-dashboard__no-checklist-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  margin: 4rem 0;
}

.assets-dashboard__no-checklist-h{
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: $primary-dark;
}

.assets-dashboard__no-checklist-p{
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $primary-dark;
}

.archive_button,
.assets_type_button,
.active_button {
  display: flex;
  align-items: center;
  background-color: white;
  // justify-content: center;
  border: 1px solid $gray_4;
  height: 40px;
  gap: 5px;
  border-radius: 5px;
}

.assets-search-dropdown {
  display: flex;
  width: 100%;
  align-items:  center;
  // gap: 10px;
  // margin-top: -35px;
}

.dotsContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  height: 30px;
  width: 30px;
  position: relative;
}

.dotsContainer:hover {
  border-radius: 100px;
  background: var(--fontcolorsgray-3, #F0F0F0);
}

.dotsContainer:active {
  border-radius: 100px;
  background: var(--fontcolorsgray-4, #DEDEDE);
}

.add_asset-button {
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border-radius: 4px;
}

.assets_dashboard-option-menu {
  div {
    margin-bottom: 4px;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    // background-color: red;
    margin-left: 6px;
    margin-right: 24px;
    cursor: pointer;
    img {
      height: 18px;
      width: 18px;
      margin-right: 10px;
    }
  }
}
.assets_dashboard-menu-item {
  &:hover {
    background-color: $gray_1;
  }
}