@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin roboto-bold {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
}

@mixin route-title {
  @include roboto-bold();
  font-size: 24px;
  line-height: 24px;
  color: #2b323b;
}

@mixin route-subtitle {
  @include roboto-bold();
  font-size: 20px;
  line-height: 24px;
  color: #2b323b;
}
