@import "../../styles/constants";
@import "../../styles/color-codes";

input.default-input {

  width: 100%;
  border: 2px solid;
  border-radius: 5px;
  padding: 10px;
  // margin: 5px 0px;
  outline: none; // safari adds a brown outline on focused elements
}

input.default-input:focus{
  outline: none;
}

.default-input {

  &.error-text {
    color: $error-highlight;
    margin-left: 5px;
    margin-top: 5px;
  }

  &.form-error {
    color: $error-highlight;
    border-color: $error-highlight;
  }
}

