@import "../../../styles/constants";

.input-with-icon {
  position: relative;

  .input {
    max-width: 550px;
    border: 2.5px solid #FFF7F7;
    box-shadow: 0 4px 6px 0 rgba(191, 191, 191, 0.5);
    padding: 15px 15px 15px 49px;
  }

  .input-icon {
    position: absolute;
    width: 12px;
    top: 20px;
    left: 22px;
  }

  .error {
    border-color: $error-highlight;
  }
}
