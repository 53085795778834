@import './color-codes.scss';
// Please use file src/utils/scss-colors/color-codes.scss for new color codes

// Layout
$content-separation: 30px;
$card-padding: 15px;
$margin-unit: 16px;
// Colors
$light-accent: #F4F8FF;
$light-grey: #DBE1EB;
$muted-grey: #CBCFD3;
$default-grey: #878787;
$hover-grey: #f0f0f0;
$error-highlight: #D47420;
$full-color-background: linear-gradient(90deg, #E2E0FF 0%, #6C63FF 100%);
$primary-blue: #1876F6;
$secondary-blue: #5B9AF9;
$strong-blue: #0D326E;
// v1
$primary: #6C63FF;
$secondary: #836464;
$tertiary: #45120F;
$warning: #e27111;
$danger: #F15382;
$success: #70AD47;
$dark: black;
$light: #AFB8C5;
$white: white;
$primaryLight: #F7FAFF;
$successLight: #D6F4DE;
$tertiaryLight: #FFF7F7;
$successDark: #C87C30;
$muted: #98A5B4;
$placeholder-color: #8A99AA;
$invalid_input_border: #E0EBFE;
$valid_input_border: #ECF3FE;
$untouched_input_bg: #FAFCFF;
$red: #E92B36;

// fonts
$gray_0:#FAFAFA;
$gray_1: #F7F7F7;
$gray_3: #F0F0F0;
$gray_5: #FAFBFC;
$gray_4: #DEDEDE;
$gray_9: #808080;
$gray_10: #404040;
$text: #2B323B;
// espectro
$primary-30: #F1F0FF;
$primary-dark: #2B323B;
$primary_back_list: #F2F4F7;
$secondary_back_light: #F8F9FA;
$error: #E92B36