.searched-users {
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #DEDEDE;
  font-weight: 500;
  font-size: 16px;
  color: black;
  .user_profile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    &__image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 5px;
      object-fit: cover;
    }
  }
  .user-action {
    justify-self: flex-end;
  }
}
