.disclaimer {
  display: flex;
  align-items: center;
  width: 100%;
  bottom: 55px;
  padding: 15px;
  background-color: #f2f2f2;
  border-radius: 8px;
  color: #404040;
  font-weight: 200;
  letter-spacing: 0.46px;
  margin-bottom: 60px;

  img {
    height: 16px;
    margin-right: 10px;
  }
}