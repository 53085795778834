@import '../../../styles/constants.scss';

.value_out_of_bounds__overlay_head {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.value_out_of_bounds__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  font-family: 'Plus Jakarta Sans';
  color: #000000;
}

.value_out_of_bounds_date_picker_container {
  display: flex;
  margin: 18px 0px;
  // width: 90%;
  align-self: center;
  // flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.value_out_of_bounds-save-button {
  padding: 4px 18px;
  margin-top: 12px;
  background-color: $primary;
  border: none;
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.value_out_of_bounds-disbale-btn {
  background-color: $muted;
}

.value_out_of_bounds-data-time-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.value_out_of_bounds-circular-progress {
  padding: 0px 12px;
  margin: 0px;
  border-radius: 4px;
  background-color: $muted;
  p {
    margin-left: 8px;
    color: white;
  }
}
.value_out_of_bounds-reverse-style {
  background-color: white;
  color: $primary;
  border: 1.5px solid $primary;
}
.value_out_of_bounds-buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
  align-items: center;
}