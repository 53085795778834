.select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 300;
  color: #808080;
  border-radius: 3px;
  padding: 10.6px 8px 9px 6px;
  background-color: white;

  &:hover {
      background-color: #F2F2F2;
  }

  &.disabled {
      background-color: #F2F2F2;
  }
}
