@import "../../../../../styles/color-codes.scss";

.addUser {
  .user-info-wrapper {
    display: flex;
    justify-content: flex-end;
    z-index: 9999;
    width: 100%;
    padding: 10px 10px 0px 10px;
    background-color: white;
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    &__userNameAndIcon {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 20px;
      font-weight: bolder;
    }

    &__userIcon > img {
      padding: 10px;
      border: 8px solid $BORDERS;
      border-radius: 50%;
      width: 75px;
      height: 75px;
      object-fit: contain;
    }
  }

  &__name {
    width: 80%;
    margin-top: -40px;

    &__input--error {
      font-size: 14px;
      line-height: 14px;
      color: $PRIMARY;
      margin-left: 95px;
    }
  }

  &__position {
    &__input--error {
      font-size: 14px;
      line-height: 14px;
      color: $PRIMARY;
      margin-left: 95px;
    }
  }

  &__location {
    &__input--error {
      font-size: 14px;
      line-height: 14px;
      color: $PRIMARY;
      margin-left: 95px;
    }
  }

  &__bottom_container {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &__buttons {
      display: flex;
      justify-content: flex-end;
      z-index: 9999;
      width: 100%;
      padding: 10px 10px 0px 10px;
      background-color: white;

      &__cancel {
        background-color: $PRIMARY_LIGHT;
        padding: 10px 22px;
        color: $PRIMARY;
        margin-right: 10px;
      }

      &__add {
        background-color: $PRIMARY;
        padding: 10px 22px;
      }
    }
  }
}