@import "constants";
@import './_mixins';

body, html {
  overflow-y: hidden;
  background-color: #F7F7F7;
  font-family: "Nunito Sans", sans-serif;
  @include scrollbars(.5em, rgba(122, 122, 122, 0.575), rgba(255, 255, 255, 0.219));
}

div, ul {
  @include scrollbars(.5em, rgba(122, 122, 122, 0.575), rgba(255, 255, 255, 0.219));
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

input {
  border: none;
  background-image: none;
  background-color: unset;
  box-shadow: none;
  outline-width: 0;
}

input[type=text], textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
p {
  margin-bottom: 0;
}

.all-unset {
  all: unset;
}

.bg-tertiary-light {
  background-color: $tertiaryLight;
}

.bg-success-dark {
  background-color: $successDark;
}

.bg-darner {
  background-color: $danger;
}

.bg-warning {
  background-color: $warning;
}

.bg-primary {
  background-color: $primary;
}
.bg-white{
  background-color: #FFF;
}
.bottom-divider {
  border-bottom: 1px solid #DAE0EB;
  padding-bottom: 9px;
}

.bottom-divider-dashed {
  border-bottom: 1px dashed #DAE0EB;
  padding-bottom: 9px;
}

.card {
  background-color: #FFF;
  border: 1px solid #DEDEDE;
  border-width: 1px;
  border-radius: 10px;
  padding: $card-padding;
  // box-shadow: 0 6px 10px 0 rgba(207, 207, 207, 0.5);
  margin-bottom: $content-separation;
  position: relative;
}

.card-scroll-table {
  overflow-x: scroll;
  height: 72vh;
  @extend .card
}

.card-image {
  margin-right: 10px;
  align-self: center;
}

.card-margin-bottom {
  margin-bottom: $content-separation;
}

.card-slim {
  background-color: #FFF;
  border-radius: 10px;
  padding: $card-padding;
  box-shadow: 0 6px 10px 0 rgba(207, 207, 207, 0.5);
  margin-bottom: 10px;
}

.clickable {
  cursor: pointer;
}

.cursor-point {
  cursor: pointer;
}

.emoji {
  margin-left: 15px;
  margin-right: 5px;
  height: 25px;
  width: 25px;
}

.error {
  color: $error-highlight;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 5%;
  left: 0;
  z-index: 1030;
}

.full-width {
  width: 100%;
}

.indent-1 {
  text-indent: 0.5em;
}

.indent-2 {
  text-indent: 1.5em;
}

.error-input {
  border: 2px solid orange;
  border-radius: 6px;
}

.main-content-margin {
  margin-top: $content-separation;
}

.mb-1 {
  margin-bottom: $margin-unit;
}

.ml-1 {
  margin-left: 10px;
}

.ml-2 {
  margin-left: 15px;
}

.mr-1 {
  margin-right: 15px;
}

.mt-1 {
  margin-top: $margin-unit;
}

.mt-2 {
  margin-top: $margin-unit * 2;
}

.mt-3 {
  margin-top: $margin-unit * 3;
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-right {
  padding-right: 0;
}

.position-relative {
  position: relative;
}

.primary-pill {
  color: #1876F6;
  border: 1px solid #1876F6;
  border-radius: 8px;
  padding: 3px 13px;
}

.right-side-divider {
  border-right: 1px solid #DAE0EB;
}

.spacing-top {
  margin-top: $content-separation;
}

.text-bold {
  font-weight: 700 !important;
}

.text-boldest {
  font-weight: 900 !important;
}

.text-center {
  text-align: center;
}

.text-larger {
  font-size: 1.2rem;
}

.text-largest {
  font-size: 1.4rem;
}

.text-left {
  text-align: left !important;
}

.text-light {
  font-weight: lighter !important;
}

.text-muted {
  color: $muted-grey;
}

.text-normal {
  font-size: 1rem;
}

.text-primary {
  color: $primary;
}

.text-right {
  text-align: right !important;
}

.text-secondary {
  color: $secondary;
}

.text-smaller {
  font-size: .8rem;
}

.text-smallest {
  font-size: .65rem;
}

.text-tertiary {
  color: $tertiary;
}

.text-tertiary-light {
  color: $tertiaryLight;
}

.to-center {
  justify-content: center;
}
.disabled-text{
  color: #878787;
}

.underline {
  text-decoration: underline;
}

.greyColored{
  color: $default-grey;
}
.body{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $text;
}
.body_xl{
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $gray_9
}
.multiple-value-text-input-item{
  border-radius: 2px;
}
