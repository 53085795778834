.custom-card {
  display: flex;
  flex-wrap: wrap;
  width: 270px;
  height: 80px;
  background-color: white;
  margin: 0 10px 10px 0;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  padding: 18px 16px;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;

  &.no-click{
    cursor: initial;
  }

  &:hover {
    box-shadow: 0 2px 3px 0 rgba(224, 224, 224, 0.5);
  }

  span {
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    width: 100%;
    margin-bottom: 5px;
  }

  &__incidents {
    flex: 1;
    height: 24px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 18px;
    color: #70ad47;
    display: flex;
    align-items: center;
  }

  &__dropdown {
    padding: 0 5px;
    cursor: pointer;
    &:hover {
      background-color: #F2F2F2;
    }

    .full-content {
      div {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        padding: unset;
        
        img {
          height: 14px;
          margin-right: 10px;
        }
      }
    }
  }
}