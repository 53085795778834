@import "../../../../../styles//color-codes.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  &__img-container {
    padding-bottom: 16px;

    &__img {
      width: 100px;
      height: 82px;
    }
  }
  
  &__pin-header {
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: '#2B323B';
  }

  &__pin {
    font-family: 'Outfit', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    margin-top: 16px;
  }

  &__qrcode-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 32px;

    &__title {
      font-family: 'Jost', sans-serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #808080;
    }

    &__code-container {
      display: flex;

      &__title {
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #808080;
        margin-right: 8px;
      }

      &__icon {
        height: 24px;
        width: 24px;
        cursor: pointer;
      }
    }

    &__description {
      font-family: 'Jost', sans-serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #808080;
    }
  }

  &__border {
    width: 100%;
    border-top: 1px solid $PRIMARY;
  }

  &__details {
    // width: 100%;
    // padding: 30px;
    margin-top: 32px;
    margin-bottom: 32px;
    
    &__labelValue {
      display: flex;
      flex-direction: row;
      justify-self: center;
      align-items: center;
      margin: 5px 0;

      &__label {
        font-family: 'Jost', sans-serif;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        min-width: 100px;
        color: $PRIMARY;
      }
    
      &__value {
        font-family: 'Jost', sans-serif;
        font-size: 13px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 18px;
      }
    }  
  }
}
