@import "../../../styles/constants.scss";

.tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  .tab-item {
    display: flex;
    border-bottom: 2px solid transparent;
    flex-direction: column;
    width: 50%;
    box-shadow: none;
    align-items: center;
    white-space: nowrap;
    color: $default-grey;
  }
  .tab-item-selected {
    border-bottom: 2px solid $primary;
    color: $primary;
  }
  .tab-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    overflow: hidden;
    text-overflow: clip;
    line-height: 38px;
  }
}

