@import '../../../styles/constants.scss';

.new_wo_page-container {
  height: 100vh;
  background-color: white;
}

.new-workorder__containerv1 {
  display: flex;
  flex-direction: column;
  width: calc(100% - 180px);
  margin-left: 180px;
  padding: 12px 12px 12px 12px;
  position: relative;
  overflow-y: scroll;
  // flex: 1;
  // height: 100%;
  height: calc(100vh - 60px);
}

.new-workorder__containerv1::-webkit-scrollbar{ 
  display: none; 
}

.new-workorder__cards-row{
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
}
.new-workorder__flex-row{
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 24px;
  justify-content: space-between;
}
.new-workorder__no-checklist-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  margin: 4rem 0;
}

.new-workorder__no-checklist-h{
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: $primary-dark;
}

.new-workorder__no-checklist-p{
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $primary-dark;
}

.new-workorder__no-checklist-margin-hor-16{
  margin: 16px 0;
}
.new-workorder_scroll-view{
  overflow-y: scroll;
  height: 65vh;
}
.shareContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#shareStyle:hover {
  border-radius: 20px;
  background: var(--fontcolorsgray-3, #F0F0F0);
}
#shareStyle:active {
  border-radius: 20px;
  background: var(--fontcolorsgray-4, #DEDEDE);
}
.new-workorder__backArrow {
  // margin-right: 16px;
}
.new-workorder__headerContainer {
  width: 100%;
}
.new-workorder__header-main {
  flex-direction: row;
  display: flex;
  width: 95%;
}
.checklistHeader_input{
  padding: 8px;
  font-size: 18px;
  background-color: $gray_0;
  border: 1px solid $gray_4;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  &:focus{
    outline: none;
    box-shadow: 0px 0px 0px 4px #E2E0FE;
  }
}
.checklistHeader__edit-container{
  min-width: 16rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
}
.checklistHeader__tab-edit{
  width: 90%;
  margin-left: 16px;
}
.new_wo_header-description {
  color: #B5B5B5;
  font-size: 15px;
  font-style: normal;
  width: 100%;
  font-weight: 400;
  line-height: 20px;
  margin-top: 4px;
}
.checklistHeader_id{
  color: #404040;
  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.new-workorder__checklist_cards {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 49%;
  padding: 6px;
  background-color: #FAFAFA;
  overflow-y: scroll;
}

.new_workOrder-full-height{
  height: calc(100% - 60px);
}

.new-workorder__checklist_card_containerv1{
  height: inherit;
}
.new-workorder__selectUsers_cards{
  justify-content: flex-start!important;
  background-color: #FFFFFF;
  border-width: 0;
  transition: all 500ms;
  max-height: calc(85vh - 230px);
  min-height: calc(85vh - 230px);
  scroll-behavior: smooth;
  transition: all 500ms;
  display: flex;
  flex-direction: column;
}

.new-workorder__checklist_cards::-webkit-scrollbar{ 
  display: none;
}
.new-workorder__checklist_card_container::-webkit-scrollbar{ 
  display: none; 
}
.new-workorder__selectUsers_cards::-webkit-scrollbar{ 
  display: none; 
}

.new-workorder__searchv1{
  input {
    width: 100%;
    border: 0px solid rgb(236, 239, 244);
    border-radius: 5px;
    background-color: transparent;
    padding: 10px;
    font-size: 1em;
    outline: none;
    margin: 5px 0;
  }
}
.new-workorder__selectUsers-search{
  margin-left: 12px;
  margin-bottom: 8px;
  input {
    width: 60%;
  }
}
.new-workorder__checklist_card-v1{
  width: 100%;
  border: 2px solid rgb(236, 239, 244);
  border-radius: 5px;
  background-color: #FFFFFF;
  padding: 4px 10px;
  font-size: 1em;
  outline: none;
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
}

.wo_restrict-width-for-routine-text {
  max-width: 50%;
}

.new-workorder__checklist_card_title-v1{
  color: #404040;
  font-family: Jost;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
  overflow-x: scroll;
  scrollbar-width: none;
}
.workorder__checklist_card_version_title-v1{
  color: $muted;
  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
}
.new-workorder__checklist_card_version_title{
  color: #404040;
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.new-workorder__checklist_card_add_title{
  color: #878787;
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.new-workorder__checklist_card_add_title_wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: calc(82vh - 230px);
  min-height: calc(82vh - 230px);
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  transition: all 500ms;
}
new-workorder__checklist_card_add_title_wrap::-webkit-scrollbar{ 
  display: none; 
}
.new-workorder__checklistSelection-title{
  color: #2B323B;
  font-family: Jost;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;
}
.new-workorder__checklistSelection-select-users-title{
  color: #2B323B;
  font-family: Jost;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.new-workorder__selectUsers-title{
  color: #878787;
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
  margin-left: 12px;
}
.new-workorder__selectUsers-checkbox-title{
  color: #2B323B;
  font-family: Jost;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
}
.new-workorder__selectUsers-checkbox-wrap{
  flex-direction: row;
  display: flex;
  align-items: center;
}
.new-workorder__upload_button {
  align-self: flex-start;
  width: 180px;
  padding: 8px 0px;
}
.new-workorder__selectUsers-search-container{
  justify-content: flex-start!important;
  background-color: #FFFFFF;
  border-width: 0;
  transition: all 500ms;
  max-height: calc(85vh - 230px);
  min-height: calc(85vh - 230px);
  overflow: hidden;
  scroll-behavior: smooth;
  transition: all 500ms;
  display: flex;
  flex-direction: column;
}
.new-workorder__selectUsers-search-container::-webkit-scrollbar{ 
  display: none; 
}
.new-workorder__selectUsers-content {
  flex: 1; 
  overflow-y: scroll;
}
.new-workorder__selectUsers-content::-webkit-scrollbar{ 
  display: none; 
}
.new-workorder__header-edit{
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #2B323B;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
}
.new-workorder__header-title{
  color: #2B323B;
  font-family: 'Outfit', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.new-workorder__cancelWo{
  display: flex;
}
.new-workorder__cancelWo-title{
  color: #6C63FF;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.new-workorder__cancelWo-description{
  color: #404040;
  font-family: 'Jost', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
}
.new-workorder__cancel-header-main{
  width: 70%;
}
.new-workorder__cancel_button{
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  font-weight: 900;
  font-size: 1em;
  outline: none;
  padding: 13px 23px;
  background-color: #2574FB;
  text-align: center;
  &:hover {
    opacity: 0.8;
  }
  background-color: #F7F7F7;
  display: flex;
}
.new-workorder__cancel_button-title{
color: #2B323B;
font-family: 'Jost', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
margin-left: 6px;
}
.new-workorder__cancelWo-info{
  margin-right: 16px;
}
.new-workorder__cancel-description-container{
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border: var(--spacing-spacing-none, 1px) solid var(--FONT_COLORS-GRAY_3, #F0F0F0);
  margin-bottom: 24px;
}
.new-workorder__cancel-description-title{
  color: var(--FONT_COLORS-GRAY_10, #404040);
  font-family: Jost;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.new-workorder__cancel-description{
  color: var(--FONT_COLORS-GRAY_10, #404040);
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.new-workorder__cancel_checklist_cards{
  justify-content: flex-start!important;
  background-color: #FAFAFA;
  border-width: 0;
  transition: all 500ms;
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.new-workorder__cancel_checklist_cards::-webkit-scrollbar{ 
  display: none; 
}
.new-workorder__cancel_checklist_card_container{
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  transition: all 500ms;
}
.new-workorder__cancel_checklist_card_container::-webkit-scrollbar{ 
  display: none; 
}
.new-workorder__total-questions{
  color: var(--FONT_COLORS-GRAY_6, #B5B5B5);
  text-align: right;
  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.cursor-default{
  cursor: default;
}
.wo_version-refresh-info {
  font-size: 16px;
  font-weight: 400;
  color: #878787;
}
.wo_version-refresh-action-text {
  font-size: 16px;
  font-weight: 400;
  color: #6C63FF;
  padding: 2px;
  padding-right: 5px;
  padding-left: 5px;
  &:hover {
    background-color: #6C63FF80;
    border-radius: 4px;
    color: white;
  }
}

.new_wo_back-btn {
  width: 42px;
  height: 42px;
  object-fit: contain;
}

.new_wo_row {
  display: flex;
  flex-direction: row;
  min-height: calc(100% - 100px);
  justify-content: space-between;
}

.new_workorder-users-selection-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
