.pdf_renderer-report-container {
  display: flex;
  justify-content: center;
  align-items: center;
  iframe {
    height: 100vh;
  }
  .pdf_renderer-loader-container {
    margin: 48px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
}