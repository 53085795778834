.reset-password {
  padding: 20px 30px;
  background: #fff;
  width: 100vw;
  height: 100vh;
  background-image: url('../../../../assets/icons/computer-email.svg');
  background-repeat: no-repeat;
  background-size: 100%;

  .arvolution-text {
    letter-spacing: 6px;
  }
  
  .content {
    margin-top: 50px;

    .blue-text {
      font-size: 17px;
      margin: 30px 0 10px 10px;
    }

    .reset-password-button {
      font-weight: bold;
    }

    .reset-password-spinner {
      margin-left: 30px;
      margin-top: 10px;
    }
  }
  
  .contact {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 35px;
    text-align: center;
    p {
      font-size: 20px;
    }
  }
  @media (max-width: 991.98px) {
    background-position-y: 88%;
    .contact {
      background-color: #fff;
    }
  }
}