// Drawer
.MuiDrawer-paperAnchorRight {
  width: 552px;
}

.MuiButton-outlined.Mui-disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

// Profile
.profile-container {
  padding: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .profile-avatar {
    width: 64px;
    height: 64px;
  }
  .profile-name {
    color: #2b323b;
    font-weight: 700;
    font-size: 15px;
    line-height: 0.9;
    letter-spacing: 0.01em;
  }
  .profile-position {
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 0.9;
    font-size: 16px;
    color: #2b323b;
    margin: 0;
  }
  .profile-user-type {
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: #808080;
    margin: 0;
  }
}

.profile-status-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin: 0 32px;
  background: #f7f7f7;
  border-radius: 4px;
  span {
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #808080;
  }
  .select-status {
    width: 90px;
    background-color: #ecf3ff;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #404040;
  }
}

//  Distribution Center Card
.distribution-center-card {
  border: 1px solid #dedede;
  border-radius: 4px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 500;
  padding: 12px 16px;
  width: 49%;
  .label {
    font-size: 12px;
    line-height: 16px;
    color: #808080;
  }
  img {
    padding-right: 4px;
  }
  .value {
    font-size: 17px;
    line-height: 24px;
    color: #2b323b;
  }
}

// Info Row
.info-row-container {
  font-family: "Nunito Sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: #2b323b;
  font-family: "Nunito Sans", sans-serif;
  .info-row-icon {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  .info-row-label {
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-left: 8px;
  }
  .info-row-value {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 500;
    font-size: 17px;
    display: flex;
    align-items: center;
  }
}
