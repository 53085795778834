.MultiValueInputList__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;
}

.MultiValueInputList__list_inner {
    width: 100%;
    margin-bottom: 4px;
}

.MultiValueInputList__list_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
}

.MultiValueInputList__list_item_inner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1px 8px;
    width: 100%;
    div {
        display: flex;
        flex-direction: row;
    }
    &:hover {
        background-color: #f5f5f5;
        .multiValue_input-item-delete-button {
            opacity: 1;
        }
    }
}

.MultiValueInputList__grabbable {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    color: #808080;
    align-self: center;
}

.MultiValueInputList__grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.multiValue_input-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.dragging {
    opacity: 0.8;
    background-color: #f0f0f0;
    border: 2px dashed #ccc;
}

.multiValue_input-item-delete-button {
    opacity: 0;
    position: absolute;
    right: 0px;
}
