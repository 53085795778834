.answeredBitView {
  padding: 12 16;
  background-color: "#2B323B";
  margin-top: 8;
  margin-bottom: 8;
}

.dropDownLabel {
  margin-top: 4px;
  // margin-bottom: 4;
  font-weight: 500;
  font-size: 17;
  // line-height: 24;
}

.signatureTitle{
  color: var(--TEXT, #2B323B);
  /* Body/BODY-BOLD */
  font-family: Jost;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 141.176% */
}

.dropDownInput {
  border-radius: 4;
  border-color: #dedede;
  border-width: 1px;
  padding: 10;
  padding-left: 15;
  padding-right: 15;
  margin-top: 16;
  margin-bottom: 16;
}

.verticalSingleOption {
  padding: 10;
  padding-top: 12;
  padding-bottom: 15;
}

.dropdownInputStyle {
  border: 2px solid;
  box-shadow: none; border-color: rgb(236, 239, 244); margin-top: 10px;
  border-radius: 5px;
  margin: 4px 0px 8px 0px;
}

.checklist-question_calendar_picker {
  // padding: 7px 0px 7px 0px;
  // border: 1px solid #DEDEDE;
  // align-items: stretch;
  // border-radius: 5px;
}
