$BACKGROUND_COLOR: lightgray;

.create_edit_pillar_image {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  overflow-x: scroll;
  border-radius: 5px;
  .single_pillar_container {
    min-width: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 300ms;
    .pillar_image {
      width: 50px;
      margin-bottom: 10px;
    }
    .pillar_text {
      font-size: 1rem;
    }
  }
  .selected {
    background-color: $BACKGROUND_COLOR;
  }
}

.Description_title {
  font-size: 20px;
}
.choose_pillar_title {
  font-size: 16px;
  margin: 20px 0 0 20px;
}
