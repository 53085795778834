$GREEN_PRIMARY: #55cd9d;
.MuiDialog-paperScrollPaper {
  width: 400px;
}
.ok_btn {
  border: 1px solid #d6d6d6 !important;
  border-radius: 5px !important;
  padding: 8px 16px !important;
  margin-right: 20px !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
  font-weight: 700 !important;
  line-height: 1.2 !important;
  height: 36px;
  img {
    width: 14px;
    margin-right: 8px;
  }
  &:focus {
    outline: none;
  }
}
.cancel_btn {
  border: 1px solid #d6d6d6 !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  margin-right: 15px !important;
  text-transform: capitalize !important;
  font-weight: 700 !important;
  line-height: 1.2 !important;
  padding: 8px 16px !important;
  height: 36px;
  img {
    width: 18px;
    margin-right: 8px;
  }
}
.dialog_title {
  h2 {
    font-weight: 600 !important;
    font-size: 16px !important;
  }
}
.button-spin-loader {
  margin-top: 4px;
}
