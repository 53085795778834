
.comment-delete-modal {
  &__content {
    width: 652px;
    top: 150px;
    padding: 12px;
    border-radius: 8px;
    left: 50%;
    position: fixed;
    transform: translate(-50%);
    background-color: white;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    &:focus {
      outline: none;
    }
    header {
      -webkit-box-align: center;
      align-items: center;
      display: flex;
      flex: 0 0 auto;
      -webkit-box-pack: justify;
      justify-content: space-between;
      transition: box-shadow 200ms ease 0s;
      z-index: 1;
      padding: 24px 24px 22px;
      box-shadow: none;
      .header_title {
        color: var(--text, #2B323B);
        /* Headers/H1 */
        font-family: Outfit;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 100% */
      }
    }
    body {
      // flex: 1 1 auto;
      // overflow: hidden auto;
      // padding: 2px 24px;
      gap: 10px;
      background-color: white;
      div{
        display: flex;
        margin-left: 12px;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        background-color: white;
        gap: 10px;
        .horizontal-line{
          display: flex;
          padding: 4px 0px;
          flex-direction: column;
          align-items: center;
          width: 450px;
          gap: 8px;
          flex: 1 0 0;
          border-bottom: 1px solid var(--fontcolorsgray-6, #B5B5B5);
        }
        .copy_button {
          padding: 8px 16px;
          height: 32px;
          margin-right: 16px;
          font-size: 12px;
          padding: 2px 15px;
          border-radius: 4px;
          box-shadow: none;
          background: var(--primary-30, #F1F0FF);
          background:  #F1F0FF;
          color: #6C63FF !important;
          text-transform: none;
          font-weight: 500;
          &:hover {
            border-radius: 4px;
            background: var(--primary-50, #E2E0FF);
          }
        }
        .descriptionBox{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          flex: 1 0 0;

          border-bottom: 1px solid var(--fontcolorsgray-6, #B5B5B5);
        }
        .description{
          color:#2B323B;
          /* Body/BODY-20line */
          font-family: Jost;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 125% */
        }; 
      }
    }
    footer {
      -webkit-box-align: center;
      align-items: center;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      transition: box-shadow 200ms ease 0s;
      z-index: 1;
      padding: 22px 24px 24px;
      box-shadow: none;
      -webkit-box-pack: end;
      justify-content: flex-end;
      .delete_button {
        height: 32px;
        margin-right: auto;
        font-size: 12px;
        padding: 2px 15px;
        border-radius: 4px;
        box-shadow: none;
        background: var(--primary, #6C63FF);
        color: white;
        text-transform: none;
        font-weight: 500;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.shareable_link_model_loader_wrapper {
  display: flex;
  flex: auto;
  padding: 24px;
  align-self: center;
}