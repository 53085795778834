@import '../../../styles/constants.scss';

.page-container{
  height: 100vh;
  background-color: white;
}

.new-workorder__container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 180px);
  margin-left: 180px;
  padding: 12px 12px;
  position: relative;
  overflow-y: scroll;
  max-height: calc(100vh - 60px);
}

.new-workorder__container::-webkit-scrollbar{ 
  display: none; 
}

.new-workorder__cards-row{
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
}
.cancel-workorder__flex-row{
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 8px;
  justify-content: space-between;
}
.new-workorder__no-checklist-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  margin: 4rem 0;
}

.new-workorder__no-checklist-h{
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: $primary-dark;
}

.new-workorder__no-checklist-p{
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $primary-dark;
}

.new-workorder__no-checklist-margin-hor-16{
  margin: 16px 0;
}
.new-workorder_scroll-view{
  overflow-y: scroll;
  height: 65vh;
}
.shareContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#shareStyle:hover {
  border-radius: 20px;
  background: var(--fontcolorsgray-3, #F0F0F0);
}
#shareStyle:active {
  border-radius: 20px;
  background: var(--fontcolorsgray-4, #DEDEDE);
}
.cancel-workorder__backArrow {
  // margin-right: 16px;
  img {
    width: 42px;
    height: 42px;
    object-fit: contain;
  }
}
.new-workorder__headerContainer {
  width: 100%;
}
.new-workorder__header-main {
  flex-direction: row;
  display: flex;
  width: 95%;
}
.checklistHeader_input{
  padding: 8px;
  font-size: 18px;
  background-color: $gray_0;
  border: 1px solid $gray_4;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  &:focus{
    outline: none;
    box-shadow: 0px 0px 0px 4px #E2E0FE;
  }
}
.checklistHeader__edit-container{
  min-width: 16rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
}
.checklistHeader__tab-edit{
  width: 90%;
  margin-left: 16px;
}
.header-description{
  color: #B5B5B5;
  font-size: 15px;
  font-style: normal;
  width: 100%;
  font-weight: 400;
  line-height: 20px;
  margin-top: 13px;
}
.checklistHeader_id{
  color: #404040;
  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.cancelled-workorder__checklist_cards{
  justify-content: flex-start!important;
  background-color: #FAFAFA;
  border-width: 0;
  transition: all 500ms;
  max-height: calc(82vh - 230px);
  min-height: calc(82vh - 230px);
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.new-workorder__checklist_card_container{
  max-height: calc(82vh - 230px);
  min-height: calc(82vh - 230px);
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  transition: all 500ms;
}
.new-workorder__selectUsers_cards{
  justify-content: flex-start!important;
  background-color: #FFFFFF;
  border-width: 0;
  transition: all 500ms;
  max-height: calc(85vh - 230px);
  min-height: calc(85vh - 230px);
  scroll-behavior: smooth;
  transition: all 500ms;
  display: flex;
  flex-direction: column;
}

.cancelled-workorder__checklist_cards::-webkit-scrollbar{ 
  display: none; 
}
.new-workorder__checklist_card_container::-webkit-scrollbar{ 
  display: none; 
}
.new-workorder__selectUsers_cards::-webkit-scrollbar{ 
  display: none; 
}

.new-workorder__search{
  input {
    width: 100%;
    border: 0px solid rgb(236, 239, 244);
    border-radius: 5px;
    background-color: transparent;
    padding: 10px;
    font-size: 1em;
    outline: none;
    margin: 5px 0;
  }
}
.new-workorder__selectUsers-search{
  margin-left: 12px;
  margin-bottom: 8px;
  input {
    width: 60%;
  }
}
.new-workorder__checklist_card{
  width: 100%;
  border: 2px solid rgb(236, 239, 244);
  border-radius: 5px;
  background-color: #FFFFFF;
  padding: 10px;
  font-size: 1em;
  outline: none;
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
}
.new-workorder__checklist_card_title{
  color: #404040;
  font-family: Jost;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.new-workorder__checklist_card_version_title{
  color: #404040;
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.new-workorder__checklist_card_add_title{
  color: #878787;
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.new-workorder__checklist_card_add_title_wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: calc(82vh - 230px);
  min-height: calc(82vh - 230px);
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  transition: all 500ms;
}
new-workorder__checklist_card_add_title_wrap::-webkit-scrollbar{ 
  display: none; 
}
.new-workorder__checklistSelection-title{
  color: #2B323B;
  font-family: Jost;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;
}
.new-workorder__checklistSelection-select-users-title{
  color: #2B323B;
  font-family: Jost;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.new-workorder__selectUsers-title{
  color: #878787;
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
  margin-left: 12px;
}
.new-workorder__selectUsers-checkbox-title{
  color: #2B323B;
  font-family: Jost;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
}
.new-workorder__selectUsers-checkbox-wrap{
  flex-direction: row;
  display: flex;
  align-items: center;
}
.new-workorder__upload_button {
  align-self: flex-start;
}
.new-workorder__selectUsers-search-container{
  justify-content: flex-start!important;
  background-color: #FFFFFF;
  border-width: 0;
  transition: all 500ms;
  max-height: calc(85vh - 230px);
  min-height: calc(85vh - 230px);
  overflow: hidden;
  scroll-behavior: smooth;
  transition: all 500ms;
  display: flex;
  flex-direction: column;
}
.new-workorder__selectUsers-search-container::-webkit-scrollbar{ 
  display: none; 
}
.new-workorder__selectUsers-content {
  flex: 1; 
  overflow-y: scroll;
}
.new-workorder__selectUsers-content::-webkit-scrollbar{ 
  display: none; 
}
.new-workorder__header-edit{
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #2B323B;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
}
.new-workorder__header-title{
  color: #2B323B;
  font-family: 'Outfit', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.new-workorder__cancelWo{
  display: flex;
}
.new-workorder__cancelWo-title{
  color: #6C63FF;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 18px;
  padding: 0px;
  margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
}
.new-workorder__cancelWo-description{
  color: #404040;
  font-family: 'Jost', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
}
.new-workorder__cancel-header-main{
  width: 70%;
}
.cancel-workorder__cancel_button{
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  font-weight: 900;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  outline: none;
  padding: 6px 12px;
  background-color: #2574FB;
  text-align: center;
  &:hover {
    opacity: 0.8;
  }
  img {
    width: 18px;
    height: 18px;
    object-fit: contain;
  }
  background-color: #F7F7F7;
  display: flex;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.new-workorder__cancel_button-title {
  color: #2B323B;
  font-family: 'Jost', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-left: 6px;
}
.new-workorder__cancelWo-info{
  margin-right: 12px;
}
.new-workorder__cancel-description-container{
  display: flex;
  padding: 6px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #F0F0F0;
  margin-bottom: 16px;
}
.new-workorder__cancel-description-title{
  color: var(--FONT_COLORS-GRAY_10, #404040);
  font-family: Jost;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.new-workorder__cancel-description{
  color: var(--FONT_COLORS-GRAY_10, #404040);
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.new-workorder__cancel_checklist_cards{
  justify-content: flex-start!important;
  background-color: #FAFAFA;
  border-width: 0;
  transition: all 500ms;
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.new-workorder__cancel_checklist_cards::-webkit-scrollbar{ 
  display: none; 
}
.new-workorder__cancel_checklist_card_container{
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  transition: all 500ms;
}
.new-workorder__cancel_checklist_card_container::-webkit-scrollbar{ 
  display: none; 
}
.new-workorder__total-questions{
  color: var(--FONT_COLORS-GRAY_6, #B5B5B5);
  text-align: right;
  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.cursor-default{
  cursor: default;
}
.cancel_wo-color-red {
  color: red;
}

.cancel_wo__delete-loader {
  margin: 0px 0px 20px 0px;
}

.cancel_wo-loader-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
}