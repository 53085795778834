$GRAY_FOUR: #dedede;
.popover_container {
  position: relative;
  width: 350px;
  min-height: 450px;
  padding: 30px 0;
  transition: all 400ms;
  .responsibles_container {
    display: flex;
    margin-bottom: 20px;
    transition: all 400ms;
  }
  .close_popover {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}

.responsible_list_container {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  overflow: hidden;
  overflow-y: scroll;
  .single_responsible_container {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-top: 1px solid $GRAY_FOUR;
  }
  .avatar_name_container {
    display: flex;
  }
  .responsible_name {
    margin-top: 7px;
    color: black;
    font-weight: 500;
    font-size: 15px;
  }
  .participant_image {
    transition: all 400ms;
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  .status_image {
    width: 20px;
    height: 20px;
    align-self: center;
  }
}
.responsible_list_container:last-child {
  border-bottom: 1px solid $GRAY_FOUR;
}
.responsible_list_container::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
