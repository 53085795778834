@import "../../../styles/constants.scss";

.checklist_renderer__container {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: #fff;
}

.checklist_renderer__container > div {
  width: 600px;
}
.ChecklistQuestions__nestedchecklist_container {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-notes-width {
  width: 90%;
}

.ChecklistQuestions__nestedchecklist_button {
  outline: none;
  display: flex;
  padding: 0.25rem 0.75rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.5rem;
  background: #f1f0ff;
  border: none;
  color: #6c63ff;
  &:hover {
    background-color: #6c63ff;
    color: #f1f0ff;
  }
  &:active {
    outline: none;
    border: 0;
    background-color: #6c63ff;
    color: #f1f0ff;
  }
}
.checklistRenderer__nestedChecklist {
  padding: 1rem;
}

.checklistRenderer__header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}
.checklistRenderer__header_container_left {
  display: flex;
  align-items: center;
  gap: 1rem;
  // padding: 8px;
}
.checklistRenderer__header_container_right {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  background-color: $gray_3;
  padding: 8px 12px;
  border-radius: 80px;
  white-space: nowrap;
  p {
    overflow: hidden;
    text-overflow: clip;
  }
  cursor: default;
}
.checklistRenderer__header_column {
  display: flex;
  flex-direction: column;
}
.checklistRenderer__header_title {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
}
.checklistRenderer__header_description {
  color: #878787;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
}
.checklistRenderer__header_company_info {
  color: #6c63ff;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
}
.ChecklistQuestions__nestedchecklist_addanother {
  color: #6c63ff;
  cursor: pointer;
  padding: 0.5rem 2rem;
  outline: none;
  border: none;
  font-weight: 600;
}
.blur_overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.success_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.checklist-submission_success_text {
  color: #2B323B;
  margin-top: 12px;
  font-size: 18px;
}
.checklist-submission_error_text {
  color: #ff0000;
  margin-top: 24px;
  font-size: 14px;
}

@media only screen and (max-width: 600px) {
  .checklist_renderer__container > div {
    max-width: 100%;
    overflow-x: hidden;
  }
}

.checklist_renderer-notes-modal {
  width: 30%;
  top: 150px;
  align-self: center;
  // background-color: red;
  padding: 12px;
  border-radius: 8px;
  left: 50%;
  position: fixed;
  transform: translate(-50%);
  background-color: white;
  display: flex;
  flex-direction: column;
  // flex: 1 1 auto;
  &:focus {
    outline: none;
  }
  header {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    transition: box-shadow 200ms ease 0s;
    z-index: 1;
    padding: 24px 24px 22px;
    box-shadow: none;
    .header_title {
      color: #2B323B;
      font-family: Outfit;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 100% */
    }
  }
  body {
    background-color: white;
    div{
      display: flex;
      margin-left: 12px;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      background-color: white;
      gap: 10px;
      .horizontal-line{
        display: flex;
        padding: 4px 0px;
        flex-direction: column;
        align-items: center;
        width: 450px;
        gap: 8px;
        flex: 1 0 0;
        border-bottom: 1px solid #B5B5B5;
      }
      .copy_button {
        padding: 8px 16px;
        height: 32px;
        margin-right: 16px;
        font-size: 12px;
        padding: 2px 15px;
        border-radius: 4px;
        box-shadow: none;
        background: #F1F0FF;
        color: #6C63FF !important;
        text-transform: none;
        font-weight: 500;
        &:hover {
          border-radius: 4px;
          background: #E2E0FF;
        }
      }
      .descriptionBox{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1;
        border-bottom: 1px solid #B5B5B5;
      }
      .description{
        color:#2B323B;
        /* Body/BODY-20line */
        font-family: Jost;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
      }; 
    }
  }
  footer {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    transition: box-shadow 200ms ease 0s;
    z-index: 1;
    padding: 22px 24px 24px;
    box-shadow: none;
    -webkit-box-pack: end;
    justify-content: flex-end;
    .delete_button {
      height: 32px;
      margin-right: auto;
      font-size: 12px;
      padding: 2px 15px;
      border-radius: 4px;
      box-shadow: none;
      background: #6C63FF;
      color: white;
      text-transform: none;
      font-weight: 500;
      &:focus {
        outline: none;
      }
    }
  }
}

.checklist_renderer-notes-modal-mobile {
  width: 80%;
  top: 150px;
  align-self: center;
  // background-color: red;
  padding: 12px;
  border-radius: 8px;
  left: 50%;
  position: fixed;
  transform: translate(-50%);
  background-color: white;
  display: flex;
  flex-direction: column;
  // flex: 1 1 auto;
  &:focus {
    outline: none;
  }
  header {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    transition: box-shadow 200ms ease 0s;
    z-index: 1;
    padding: 24px 24px 22px;
    box-shadow: none;
    .header_title {
      color: #2B323B;
      font-family: Outfit;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 100% */
    }
  }
  body {
    background-color: white;
    div{
      display: flex;
      margin-left: 12px;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      background-color: white;
      gap: 10px;
      .horizontal-line{
        display: flex;
        padding: 4px 0px;
        flex-direction: column;
        align-items: center;
        width: 450px;
        gap: 8px;
        flex: 1 0 0;
        border-bottom: 1px solid #B5B5B5;
      }
      .copy_button {
        padding: 8px 16px;
        height: 32px;
        margin-right: 16px;
        font-size: 12px;
        padding: 2px 15px;
        border-radius: 4px;
        box-shadow: none;
        background: #F1F0FF;
        color: #6C63FF !important;
        text-transform: none;
        font-weight: 500;
        &:hover {
          border-radius: 4px;
          background: #E2E0FF;
        }
      }
      .descriptionBox{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1;
        border-bottom: 1px solid #B5B5B5;
      }
      .description{
        color:#2B323B;
        /* Body/BODY-20line */
        font-family: Jost;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
      }; 
    }
  }
  footer {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    transition: box-shadow 200ms ease 0s;
    z-index: 1;
    padding: 22px 24px 24px;
    box-shadow: none;
    -webkit-box-pack: end;
    justify-content: flex-end;
    .delete_button {
      height: 32px;
      margin-right: auto;
      font-size: 12px;
      padding: 2px 15px;
      border-radius: 4px;
      box-shadow: none;
      background: #6C63FF;
      color: white;
      text-transform: none;
      font-weight: 500;
      &:focus {
        outline: none;
      }
    }
  }
}
.shareable_link_model_loader_wrapper {
  display: flex;
  flex: auto;
  padding: 24px;
  align-self: center;
}
.notes-container {
  display: "flex";
  justify-content: "center";
  align-items: "center";
}

.delete_button {
  background-color: white;
  border: none;
  padding: 8px;
  border-radius: 6px;

  &:hover {
    background-color: #F2F2F2;
  }
}

.nested_footer {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  gap: 20px;
  overflow-x: hidden;
}

.go_back_button{
  display: flex;
  align-items: center;
  background-color: white;
  justify-content: center;
  border: 1px solid $gray_4;
  border-radius: 5px;
  gap: 8px;
  height: 40px;
}

.delete_instance_button {
  display: flex;
  align-items: center;
  background-color: white;
  justify-content: center;
  flex: 0.5;
  border: 1px solid $gray_4;
  height: 40px;
  gap: 5px;
  border-radius: 5px;
}

.delete_instance_button > span {
  font-size: 15px;
  color: $red;
  font-weight: 700;
}

.cancel_button{
  background: transparent;
  border: 1px solid $gray_4;
  color: black;
  border-radius: 4px;
}