@font-face {
  font-family: 'Nunito Sans';
  src: url('./../assets/fonts/NunitoSans-Regular.woff2') format('woff2'),
  url('./../assets/fonts/NunitoSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./../assets/fonts/NunitoSans-ExtraLight.woff2') format('woff2'),
  url('./../assets/fonts/NunitoSans-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./../assets/fonts/NunitoSans-ExtraBoldItalic.woff2') format('woff2'),
  url('./../assets/fonts/NunitoSans-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./../assets/fonts/NunitoSans-LightItalic.woff2') format('woff2'),
  url('./../assets/fonts/NunitoSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./../assets/fonts/NunitoSans-ExtraLightItalic.woff2') format('woff2'),
  url('./../assets/fonts/NunitoSans-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./../assets/fonts/NunitoSans-BlackItalic.woff2') format('woff2'),
  url('./../assets/fonts/NunitoSans-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./../assets/fonts/NunitoSans-Black.woff2') format('woff2'),
  url('./../assets/fonts/NunitoSans-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./../assets/fonts/NunitoSans-Light.woff2') format('woff2'),
  url('./../assets/fonts/NunitoSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./../assets/fonts/NunitoSans-BoldItalic.woff2') format('woff2'),
  url('./../assets/fonts/NunitoSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./../assets/fonts/NunitoSans-ExtraBold.woff2') format('woff2'),
  url('./../assets/fonts/NunitoSans-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./../assets/fonts/NunitoSans-Bold.woff2') format('woff2'),
  url('./../assets/fonts/NunitoSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./../assets/fonts/NunitoSans-SemiBoldItalic.woff2') format('woff2'),
  url('./../assets/fonts/NunitoSans-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./../assets/fonts/NunitoSans-SemiBold.woff2') format('woff2'),
  url('./../assets/fonts/NunitoSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./../assets/fonts/NunitoSans-Italic.woff2') format('woff2'),
  url('./../assets/fonts/NunitoSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./../assets/fonts/Quicksand/Quicksand-Light.ttf') format('truetype'),
  url('./../assets/fonts/Quicksand/Quicksand-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./../assets/fonts/Quicksand/Quicksand-Medium.ttf') format('truetype'),
  url('./../assets/fonts/Quicksand/Quicksand-Medium.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./../assets/fonts/Quicksand/Quicksand-Regular.ttf') format('truetype'),
  url('./../assets/fonts/Quicksand/Quicksand-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./../assets/fonts/Quicksand/Quicksand-SemiBold.ttf') format('truetype'),
  url('./../assets/fonts/Quicksand/Quicksand-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./../assets/fonts/Quicksand/Quicksand-Bold.ttf') format('truetype'),
  url('./../assets/fonts/Quicksand/Quicksand-Bold.ttf') format('truetype');
  font-weight: 700;
}
