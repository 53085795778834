$ACTION_LOG_CARD_CONTAINER_SHADOW: #6464644a;
$ACTION_LOG_CARD_CONTAINER_SHADOW_HOVER: #64646495;
$DESCRIPTION_COLOR: #2b323bd9;
$ORIGIN_COLOR: #878787;
$IMAGE_COUNT_BACKGROUND: #f0f0f0;
$SAFETY_COLOR: #2574fb;
$SAFETY_BACKGROUND: #d9eeff;
$PLANNING_COLOR: #8325fb;
$PLANNING_BACKGROUND: #ecd9ff;
$STOCK_COLOR: #1fb789;
$STOCK_BACKGROUND: #cdf7e8;
$FLEET_COLOR: #d8821d;
$FLEET_BACKGROUND: #f8e1b6;
$DISTRIBUTION_COLOR: #30ac2d;
$DISTRIBUTION_BACKGROUND: #edffe4;
$PEOPLE_COLOR: #b01757;
$PEOPLE_BACKGROUND: #ffd9e9;
$MANAGEMENT_COLOR: #c6b200;
$MANAGEMENT_BACKGROUND: #faf8d4;
$RESPONSIBLE_BG_COLOR: #adefd1ff;
$RESPONSIBLE_TEXT_COLOR: #00203fff;

.action_log_card_container_alert_end_date {
  box-shadow: 0px 2px 3px red !important;
}
.action_log_card_container {
  width: 260px;
  display: flex;
  padding: 8px 16px;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  box-shadow: 0px 2px 3px $ACTION_LOG_CARD_CONTAINER_SHADOW;
  transition: all 300ms;
  cursor: pointer;
  .pillar_section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .pillar_name_container {
      display: flex;
      .pillar_name {
        display: flex;
        padding: 0;
        align-self: center;
        font-weight: bold;
        text-transform: uppercase;
        padding: 5px 13px;
        padding-top: 7px;
        letter-spacing: 0.03em;
        font-size: 10px;
        line-height: 10px;
        text-align: center;
        border-radius: 20px;
      }
    }
  }
  .description {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    font-size: 15px;
    line-height: 1.2;
    color: $DESCRIPTION_COLOR;
    letter-spacing: 0.02em;
    overflow: hidden;
    font-weight: normal;
    padding-right: 5px;
    overflow-wrap: anywhere;
  }
  .origin_end_date_section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .origin {
      font-size: 12px;
      line-height: 1;
      color: $ORIGIN_COLOR;
      margin-right: 5px;
    }
    .end_date {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 5px;
      min-width: 33%;
      img {
        width: 23px;
        height: 23px;
        object-fit: contain;
      }
      .end_date_text {
        font-size: 9px;
        line-height: 9px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        padding-top: 2px;
      }
      .alert_end_date {
        color: red;
      }
    }
  }
  .participant_images_comment_section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .participant_image_section {
      display: flex;
      img {
        cursor: pointer;
        width: 30px;
        height: 30px;
        object-fit: contain;
        border-radius: 50%;
        margin-right: 4px;
      }
    }
    .image_comment_section {
      display: flex;
      align-items: center;
      padding-right: 5px;
      .image_count {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $IMAGE_COUNT_BACKGROUND;
        border-radius: 13px;
        padding: 3px 8px;
        opacity: 0.7;
        font-size: 10px;
        line-height: 10px;
        font-weight: bold;
        .attach_image {
          margin-right: 2px;
          width: 17px;
          height: 17px;
        }
      }
      .comment_image {
        margin-left: 5px;
        width: 27px;
        height: 27px;
      }
    }
  }
}

.action_log_card_container:hover {
  box-shadow: 0px 3px 5px $ACTION_LOG_CARD_CONTAINER_SHADOW_HOVER;
  transition: all 300ms;
}

.safety {
  color: $SAFETY_COLOR;
  background: $SAFETY_BACKGROUND;
}
.planning {
  color: $PLANNING_COLOR;
  background: $PLANNING_BACKGROUND;
}
.stock {
  color: $STOCK_COLOR;
  background: $STOCK_BACKGROUND;
}
.fleet {
  color: $FLEET_COLOR;
  background: $FLEET_BACKGROUND;
}
.distribution {
  color: $DISTRIBUTION_COLOR;
  background: $DISTRIBUTION_BACKGROUND;
}
.people {
  color: $PEOPLE_COLOR;
  background: $PEOPLE_BACKGROUND;
}

.management {
  background: $MANAGEMENT_BACKGROUND;
  color: $MANAGEMENT_COLOR;
}

.action_log_card_remaining_participant {
  background: $RESPONSIBLE_BG_COLOR;
  color: $RESPONSIBLE_TEXT_COLOR;
  width: 30px;
  height: 30px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
