@import "../../../../styles/constants.scss";

.basicButton__main{
  padding: 8px 12px;
  background: $primary-30;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: $primary;
  outline: none;
  border: none;
}