$primary: #6c63ff;
.search-header {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 15px;
  justify-content: space-between;
}
.search-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 12px 12px 16px;
  height: 48px;
  border: 2px solid #f0f0f0;
  border-radius: 8px;
}
.user-list-container {
  background-color: #fff;
  padding: 32px;
  text-align: left;
  overflow: auto;
  height: 90vh;
  .ReactTable.-striped .rt-tr.-even {
    background-color: inherit !important;

    &:hover {
      background-color: inherit !important;
    }
    &:focus {
      background-color: inherit !important;
    }
  }

  .ReactTable {
    .rt-td {
      text-align: left !important;
    }

    .rt-thead.-header {
      display: flex;
      align-items: center;
      flex-direction: row;
      height: 56px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
    }
    .rt-tr-group {
      height: 64px;
      display: flex;
      align-items: center;
      flex-direction: row;
      background: #fff;

      &:hover {
        background-color: #fafafa !important;
      }
      &:focus {
        background-color: #f1f0ff !important;
      }
    }
  }
}

.user-table {
  height: 65vh;
  min-height: 20vh;

  .-pageSizeOptions {
    display: none;
  }

  // .-striped {
  //   height: 95%;
  // }

  .table-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    div,
    button {
      padding: 10px 20px;
    }
  }

  .page {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  input {
    width: 50%;
    border: 2px solid #eceff4;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px;
    font-size: 1em;
    outline: none;
    margin: 0 10px 0 0;
  }
}

.user-filter {
  display: flex;
  flex-direction: row;
  margin-top: 30px;

  .filter {
    margin: 0 20px 0 0;
  }
  .label {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .reset {
    align-self: flex-end;
    padding-bottom: 5px;
    cursor: pointer;

    span {
      font-weight: 700;
      font-size: 12px;
      line-height: 24px;
    }

    img {
      margin-right: 8px;
    }
  }

  .chart-dropdown {
    border-radius: 6px;
    border: 1px solid #d6d6d6;
  }
}

.hidden {
  display: none;
}
.upload_button_container {
  position: absolute;
  top: -10px;
  right: 150px;
  width: 130px;
}
.add_user_container {
  position: absolute;
  top: -10px;
  right: 0px;
}
.upload_button_filled {
  color: #fff;
  border-radius: 5px;
  background-color: #e92b36;
  padding: 8px 12px;
  font-weight: 400;
}
.add_user_button_filled {
  position: absolute;
  width: 140px;
  right: 0px;
  color: #fff;
  border-radius: 5px;
  background-color: #e92b36;
  padding: 8px 12px;
  font-weight: 400;
}
.user-search-container {
  position: relative;
}
.user-checklistHeader__back {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
  gap: 8px;
  padding: 32px 32px 0px 32px;
  width: 100% !important ;
}

.chart-dropdown {
  height: 32px;
  border-radius: 6px;
  background-color: white;
  font-size: 12px;

  .dropdown-label {
    padding: 6px 10px;
    color: #404040;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
  .chev-down-icon {
    margin-left: 10px;
  }
}
.right-section-button {
  text-align: "center";
  line-height: "56px";
  vertical-align: "center";
  height: "56px";
  width: "240px";
  align-items: "center";
  cursor: "pointer";
  border: "1px solid black";
  border-color: "#F0F0F0";
  background-color: "#FFFFFF";
}

.user-roles__flex-row{
  display: flex;
  gap: 8px;
}