@import '../../../styles/constants.scss';

.button-with-icon {
  border-radius: 4px;
  font-size: 1em;
  outline: none;
  padding: 8px 18px;
  background-color: $untouched_input_bg;
  color: $primary;
  font-weight: 500;
  border: 0.1rem solid $primary;
  text-align: center;
  &:hover {
    opacity: 0.8;
  }
}

.button-with-img-icon {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 8px;
  object-fit: contain;
}
