@import "../../../styles/constants.scss";

.acknowledgementBitContainer {
  display: flex;
  padding: 12px;
  background-color: $gray_5;
  align-items: center;
  gap: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.acknowledgementBitContainer > span {
  font-size: 17px;
  font-weight: 500;
}