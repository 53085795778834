@import '../../../styles/constants.scss';

.download_report-main-container {
  height: 100vh;
  background-color: white;
  font-family: 'Outfit';
}
.download-report__container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 180px);
  margin-left: 180px;
  padding: 12px 12px 12px 12px;
  overflow-y: scroll;
  max-height: calc(100vh - 40px);
}

.checklist-builder__cards-row{
  display: flex;
  flex-wrap: wrap;
}
.report_checklist-builder__flex-row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  h1 {
    margin-bottom: 0px;
  }
}
.checklist-builder__no-checklist-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  margin: 4rem 0;
}

.checklist-builder__no-checklist-h{
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: $primary-dark;
}

.checklist-builder__no-checklist-p{
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $primary-dark;
}

.checklist-builder__no-checklist-margin-hor-16{
  margin: 16px 0;
}
.checklist-builder_scroll-view{
  overflow-y: scroll;
  height: 65vh;
}
.reports__tab {
  margin-bottom: 8px;
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.reports__tab{
  .tabs .tab-item-selected{
    border-bottom: 2px solid $primary;
    color: $primary;
  }
  .tabs{
    box-shadow: none !important;
  }
  .tab-item{
    width: 76px;
  }
  .reports__not-selected-tab{
    border-bottom: 0px;
    color: #878787
  }
}
.download_wo__button{
  gap: 8px;
  color: $primary;
  outline: none;
  background-color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.download_report-deleted-tag {
  display: inline-block;
  padding: 4px 12px 4px 12px;
  text-align: left;
  background-color: $COMPLEMENTARY2;
  color: white;
  font-size: 12px;
  margin-left: 8px;
  border-radius: 24px;
}
.download_report-work-order-title {
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}